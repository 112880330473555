// TODO: comment
import { Box, Container, Typography } from '@material-ui/core';
import { getArticle } from 'api';
import { OurThinkingModel } from 'api/types';
import { Breadcrumbs } from 'components/navigation';
import { Debug } from 'components/util';
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

interface Params {
  article: string;
}

const PageOurThinkingArticle = () => {
  const params: Params = useParams();
  const [article, setArticle] = useState<null | OurThinkingModel>(null);

  useEffect(() => {
    const load = async () => {
      const article = await getArticle(params.article);
      if (article) {
        setArticle(article);
      }
    };

    load();
  });

  return (
    <Fragment>
      <Helmet>
        <title>Article</title>
      </Helmet>

      <Breadcrumbs
        trail={[
          { label: 'Home', href: '/home' },
          { label: 'Our Thinking', href: '/our-thinking' },
          { label: (article && article.title) || '' },
        ]}
      />

      <Container>
        <Box paddingY={4}>
          {article !== null && (
            <Fragment>
              <Typography variant="h3" component="h1">
                {article.title}
              </Typography>
            </Fragment>
          )}

          <Debug data={article} />
        </Box>
      </Container>
    </Fragment>
  );
};

export default PageOurThinkingArticle;
