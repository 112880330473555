// TODO: comment

import {
  AdditionalInfo,
  Disclaimer,
  PortfolioHoldingsTable,
  SaveFund,
} from './components';
import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  BrandColours,
  GREY_DARK,
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '@abrdn-latest/config';
import { ClearIcon, SearchIcon } from 'icons';
import { ExportButton, InfoPopper } from 'components/ui';
import { Fragment, useEffect, useState } from 'react';
import { FundResponse, FundState, FundSummaryResponse } from 'api/types';
import {
  getClientAccountHoldings,
  getClientAccountHoldingsDownloadUri,
  getClientAccountSummary,
  getFundData,
  getFundSummary,
  getPortfolioHoldingDownloadUri,
  getSavedFundList,
} from 'api';
import { getLocalisedDate, roundMoney } from 'utils';

import { Anchor } from 'components/core';
import { Breadcrumbs } from 'components/navigation';
import { DataError } from 'components/no-data';
import { DatePicker } from '@material-ui/pickers';
import { FundSummary } from '@abrdn';
import { Helmet } from 'react-helmet';
import Skeleton from '@material-ui/lab/Skeleton';
import { Stack } from '@abrdn-latest/core';
import { downloadFile } from 'api/utils';
import { useParams } from 'react-router-dom';
import { useRouter } from '@abrdn-latest/use';
import { useTranslation } from 'react-i18next';

export const PageFundDetail = (page: any) => {
  const { t } = useTranslation(['common']);

  const params: any = useParams();

  const router = useRouter();

  const query = router.query as any;

  const clientSection: boolean | string = query.client || false;

  // portfolio holdings code...
  const fundCode: string = params?.fundCode;
  // client holdings code
  const clientId: string = params?.clientId;
  const accountId: string = params?.accountId;

  const IS_ACCOUNTS: boolean = !!(clientId && accountId);

  const IS_SEG_FUND: boolean = IS_ACCOUNTS;

  const content = {
    noResults: 'No results found',
    noFundObjective: 'No Fund Objective Available',
    noFund:
      'Unfortunately we are not currently able to display the holdings for this fund. If you require additional holdings information please use the Messages function to contact our Client Service team who can assist you.',
    labels: {
      less: 'Less',
      more: 'More',
      //
      fundSize: 'Fund size',
      baseCurrency: 'Base currency',
      fullDetails: 'View full details',
      download: 'Download XLSX',
      search: 'Search Security Description or ISIN',
    },
    fundObjective: 'Fund objective',
    valuationDate: {
      title: 'Valuation date',
      content:
        'You can change the valuation date by selecting from the dropdown menu of available valuation dates.',
    },
    disclaimer:
      'Underlying portfolio holding data for pooled funds will be shown in line with our disclosure policy schedule and may not align with your account valuation.',
    disclaimerAccounts:
      'Valuations displayed are indicative. Audited valuations are available in the periodic reporting provided. Download to confirm the valuation pricing point. For further details see page information.',
    securities: 'Securities',
    info: [
      !IS_SEG_FUND
        ? 'You may favourite a fund by selecting the star icon at the top of the page. Favourite funds will appear at the top of your Portfolio Holdings view.'
        : '',
      'You can change the valuation date by selecting from the dropdown menu of available valuation dates.',
      'The fund size header data is a rounded indicative value in the base currency of the fund.',
      'You can export the full holdings of the fund with additional data in excel format using the download function.',
      'The charts provide a visual indicative view of the fund composition based upon the valuation date displayed.',
      'You can use the search bar in the securities panel to find specific securities held in this fund.',
      'The data sourced to display valuations is regularly validated and updated with applicable corrections to quantities and prices to provide accurate information. However, this may not reconcile with the audited valuations provided in periodic reporting due to timing differences and the data sources pricing methodology, such as pricing points, pricing hierarchy, rounding and/or fx rates.',
    ],
  };

  const [fundSummary, setFundSummary] = useState<FundSummaryResponse>();
  const [fundData, setFundData] = useState<FundResponse>();

  const [status, setState] = useState<string>(STATUS_LOADING);

  // saved funds
  const [userSavedFunds, setUserSavedFunds] = useState<string[]>([]);

  useEffect(() => {
    const load = async () => {
      const fund = fundCode
        ? await getFundSummary(fundCode)
        : await getClientAccountSummary(clientId, accountId);

      if (fund) {
        setFundSummary(fund);
      }

      const saved = await getSavedFundList();
      setUserSavedFunds(saved);
    };

    load();
  }, [fundCode]);

  const [assetClassMapping, setAssetClassMapping] = useState<any>();
  //
  useEffect(() => {
    const mapping: any = {};

    if (fundSummary && fundSummary.instrumentAssetClassSummary) {
      fundSummary.instrumentAssetClassSummary.forEach(
        (value, index: number) => {
          mapping[value.instrumentAssetClass] =
            BrandColours[index % BrandColours.length];
        }
      );
    }

    setAssetClassMapping(mapping);
  }, [fundCode, fundSummary]);

  // initial fund state - this is used to query the api
  const initialState: FundState = {
    pageNumber: 0,
    pageSize: 50,
    pageSortOrder: 'asc',
    pageFilter: '',
    pageSortBy: 'assetclass',
    valuationDate: null,
  };

  // fund state
  const [fundState, setFundState] = useState<FundState>(initialState);

  // handle changing of the date
  const handleDateChange = (date: Date) => {
    setFundState({ ...initialState, ...{ valuationDate: date } });

    clearSearch();
  };

  const clearSearch = () => {
    try {
      const el = document.getElementById('searchfilter');

      if (el) {
        // @ts-ignore
        el.value = '';
      }
    } catch (e) {}
  };

  // handle keyword search
  const setSearch = (str: string) => {
    setFundState({
      ...fundState,
      ...{ pageFilter: str, pageNumber: initialState.pageNumber },
    });
  };

  const onChange = (state: FundState) => {
    if (
      typeof state.pageSize !== 'undefined' &&
      state.pageSize !== fundState.pageSize
    ) {
      setFundState({ ...fundState, ...state, ...{ pageNumber: 0 } });
    } else {
      setFundState({ ...fundState, ...state });
    }
  };

  let timer: any = null;

  const handleSearch = (ev: any) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = setTimeout(() => {
      // get the value from the search input,
      const value: string = String(ev.target.value)
        // .replace(/([\[/\\<>\(\)?]+)/gi, '')
        .trim();

      setSearch(value);
      clearTimeout(timer);
      timer = null;
    }, 700);
  };

  useEffect(() => {
    const load = async () => {
      setState(STATUS_LOADING);

      try {
        const fundData = fundCode
          ? await getFundData(
              fundCode,
              fundState.valuationDate,
              fundState.pageNumber,
              fundState.pageSize,
              fundState.pageSortBy,
              fundState.pageSortOrder,
              fundState.pageFilter
            )
          : await getClientAccountHoldings(
              clientId,
              accountId,
              fundState.valuationDate,
              fundState.pageNumber,
              fundState.pageSize,
              fundState.pageSortBy,
              fundState.pageSortOrder,
              fundState.pageFilter
            );

        if (fundData) {
          setState(STATUS_SUCCESS);
          setFundData(fundData);

          const fund = fundCode
            ? await getFundSummary(fundCode, fundState.valuationDate)
            : await getClientAccountSummary(
                clientId,
                accountId,
                fundState.valuationDate
              );

          if (fund) {
            setFundSummary(fund);
          } else {
            setFundSummary(undefined);
          }
        } else {
          setState(STATUS_FAILED);

          setFundSummary(undefined);
        }
      } catch (e) {
        setState(STATUS_FAILED);

        // setFundData(undefined);
        setFundSummary(undefined);
      }
    };

    load();
  }, [fundCode, fundState]);

  const [summaryOpen, setSummaryOpen] = useState<boolean>(false);
  const toggleSummary = () => {
    setSummaryOpen(!summaryOpen);
  };

  const handleDownload = async () => {
    if (fundData) {
      if (fundCode) {
        downloadFile(
          getPortfolioHoldingDownloadUri(
            fundData.data.fundCode,
            fundData.data.valuationDate
          ),
          `${fundData.data.valuationDate}-${fundData.data.fundName}.xlsx`
        );
      } else {
        downloadFile(
          getClientAccountHoldingsDownloadUri(
            clientId,
            accountId,
            fundData.data.valuationDate
          ),
          `${fundData.data.valuationDate}-${fundData.data.fundName}.xlsx`
        );
      }
    }
  };

  const FundObjective = () => {
    const SHOW_MORE_ONLY: boolean = false;

    if (fundSummary) {
      if (typeof fundSummary.fundObjective === 'undefined') {
        return null;
      }

      const fullText: string =
        fundSummary.fundObjective || content.noFundObjective;

      const characterLimit: number =
        fullText.length > 180 ? 160 : fullText.length;

      let limit: number | undefined = summaryOpen ? undefined : characterLimit;

      const displayText: string = fullText.substr(0, limit).trim();

      return (
        <Box maxWidth={770}>
          <Typography variant="h4">{content.fundObjective}</Typography>
          <Typography paragraph>
            <Fragment>
              {displayText}
              {fullText.length > characterLimit &&
                ((SHOW_MORE_ONLY && summaryOpen === false) ||
                  !SHOW_MORE_ONLY) && (
                  <Fragment>
                    {' '}
                    <Anchor
                      onClick={toggleSummary}
                      style={{ fontSize: 'inherit' }}
                    >
                      {fullText.length > characterLimit && !summaryOpen
                        ? '...'
                        : ''}
                      {summaryOpen ? content.labels.less : content.labels.more}
                    </Anchor>
                  </Fragment>
                )}
            </Fragment>
          </Typography>
        </Box>
      );
    }

    return null;
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {IS_ACCOUNTS ? 'Accounts' : t('common:titles.portfolio-holdings')}{' '}
          {fundData ? `- ${fundData.data.fundName}` : ''}
        </title>
      </Helmet>

      <Paper>
        <Breadcrumbs
          trail={[
            {
              label: 'Home',
              href: '/home',
            },
            IS_ACCOUNTS || clientSection
              ? clientSection
                ? {
                    label: 'Accounts',
                    href: `/accounts/${clientSection}/accounts`,
                  }
                : {
                    label: 'Accounts',
                    href: `/accounts/${clientId}/accounts`,
                  }
              : {
                  label: 'Portfolio Holdings',
                  href: '/portfolio-holdings',
                },
            {
              label: fundData ? fundData.data.fundName : '',
            },
          ]}
        />

        <Container>
          <Box paddingY={3}>
            <Grid container spacing={3} alignItems="stretch">
              <Grid item xs={12} sm={8}></Grid>
              {fundData && (
                <Grid item xs={12} sm={4}>
                  <Box textAlign="right">
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      justifyContent="flex-end"
                    >
                      {/* Save/Favoutite functionality */}
                      {typeof Storage !== 'undefined' && !IS_SEG_FUND && (
                        <SaveFund
                          fundCode={fundData.data.fundCode}
                          saved={userSavedFunds.includes(
                            fundData.data.fundCode
                          )}
                          showLabel
                        />
                      )}

                      {/* Info popup */}
                      <AdditionalInfo>
                        {content.info.map((item, index: number) => {
                          if (item) {
                            return (
                              <Typography paragraph key={`t-${index}`}>
                                {item}
                              </Typography>
                            );
                          }

                          return null;
                        })}
                      </AdditionalInfo>
                    </Stack>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Container>

        <Container>
          {status !== STATUS_FAILED && (
            <Fragment>
              {/* page title */}
              <Typography component="h1" variant="h3">
                {fundData ? fundData.data.fundName : <Skeleton width={300} />}
              </Typography>

              {/* fund objective */}
              {fundSummary && !IS_SEG_FUND && <FundObjective />}
            </Fragment>
          )}
        </Container>

        {fundData && (
          <Fragment>
            <Container>
              <Box paddingY={6}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  component="dl"
                  className="infolist"
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="body1" component="dt">
                      <InfoPopper {...content.valuationDate} />
                    </Typography>
                    <dd>
                      {fundCode ? (
                        <Fragment>
                          {fundData.data.valuationDates &&
                          fundData.data.valuationDates.length > 1 ? (
                            <Fragment>
                              <DatePicker
                                className={'abrdn-picker'}
                                autoOk
                                disableFuture
                                minDate={(() => {
                                  const twoYearsAgo = new Date();
                                  twoYearsAgo.setFullYear(
                                    twoYearsAgo.getFullYear() - 2
                                  );

                                  const minAvailable = new Date(
                                    fundData.data.valuationDates[0]
                                  );

                                  if (minAvailable > twoYearsAgo) {
                                    return minAvailable;
                                  }

                                  return twoYearsAgo;
                                })()}
                                maxDate={
                                  new Date(
                                    fundData.data.valuationDates[
                                      fundData.data.valuationDates.length - 1
                                    ]
                                  )
                                }
                                value={
                                  fundData.data.valuationDate
                                    ? new Date(fundData.data.valuationDate)
                                    : undefined
                                }
                                format="MMMM YYYY"
                                views={['year', 'month']}
                                openTo="year"
                                onChange={() => {}}
                                onAccept={(date: any) => {
                                  handleDateChange(date.toDate());
                                }}
                              />
                            </Fragment>
                          ) : (
                            getLocalisedDate(
                              fundData.data.valuationDate
                            ).format('MMM YYYY')
                          )}
                        </Fragment>
                      ) : (
                        <DatePicker
                          className={'abrdn-picker'}
                          autoOk
                          disableFuture
                          minDate={
                            fundData.data.minimumValuationDate
                              ? new Date(fundData.data.minimumValuationDate)
                              : (() => {
                                  const twoYearsAgo = new Date();
                                  twoYearsAgo.setFullYear(
                                    twoYearsAgo.getFullYear() - 2
                                  );

                                  return twoYearsAgo;
                                })()
                          }
                          maxDate={
                            fundData.data.maximumValuationDate
                              ? new Date(fundData.data.maximumValuationDate)
                              : new Date()
                          }
                          value={new Date(fundData.data.valuationDate)}
                          format="DD MMMM YYYY"
                          views={['year', 'month', 'date']}
                          openTo="year"
                          onChange={() => {}}
                          onAccept={(date: any) => {
                            handleDateChange(date.toDate());
                          }}
                        />
                      )}
                    </dd>
                  </Grid>

                  {status !== STATUS_FAILED && (
                    <Fragment>
                      <Grid item xs={12} sm={3}>
                        <Typography variant="body1" component="dt">
                          {content.labels.fundSize}
                        </Typography>
                        <Typography variant="body1" component="dd">
                          {fundData.data.totalMarketValueAmountFundCurrency ? (
                            <Fragment>
                              {fundData.data.fundBaseCurrencyCode}{' '}
                              {roundMoney(
                                fundData.data.totalMarketValueAmountFundCurrency
                              )}
                            </Fragment>
                          ) : (
                            '-'
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography variant="body1" component="dt">
                          {content.labels.baseCurrency}
                        </Typography>
                        <Typography variant="body1" component="dd">
                          {fundData.data.fundBaseCurrencyCode || '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography variant="body1" component="dt">
                          {content.labels.fullDetails}
                        </Typography>
                        <Typography variant="body1" component="dd">
                          <ExportButton
                            label={content.labels.download}
                            onClick={handleDownload}
                          />
                        </Typography>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
                <Box paddingY={2} maxWidth={480}>
                  <Typography variant="body2">
                    {IS_ACCOUNTS
                      ? content.disclaimerAccounts
                      : content.disclaimer}
                  </Typography>
                </Box>
              </Box>

              {/* draw the over view charts */}
              <FundSummary
                summary={fundSummary}
                assetMapping={assetClassMapping}
              />
            </Container>
          </Fragment>
        )}

        {status === STATUS_FAILED && (
          <DataError>
            <Typography paragraph>{content.noFund}</Typography>
          </DataError>
        )}
      </Paper>

      {status !== STATUS_FAILED && (
        <Paper style={{ backgroundColor: GREY_DARK }}>
          <Box paddingY={6}>
            {fundData && (
              <Fragment>
                <Container>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md>
                      <Typography variant="h4" style={{ margin: 0 }}>
                        {content.securities}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Paper>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="searchfilter"
                          placeholder={content.labels.search}
                          onChange={handleSearch}
                          InputProps={{
                            startAdornment: (
                              <SearchIcon
                                fontSize="small"
                                style={{ marginRight: 10 }}
                              />
                            ),
                            endAdornment: (
                              <IconButton
                                color="primary"
                                aria-label="clear"
                                component="span"
                                onClick={() => {
                                  setSearch('');
                                  clearSearch();
                                }}
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            ),
                          }}
                        />
                      </Paper>
                    </Grid>
                  </Grid>

                  <Box paddingTop={6}>
                    {fundData.data.holdings.length > 0 ? (
                      <PortfolioHoldingsTable
                        fundCode={fundCode}
                        search={fundState.pageFilter}
                        data={fundData}
                        onChange={onChange}
                        mapping={assetClassMapping}
                      />
                    ) : (
                      <Box paddingY={4} textAlign="center">
                        {content.noResults}
                      </Box>
                    )}
                  </Box>
                </Container>
              </Fragment>
            )}

            <Disclaimer />
          </Box>
        </Paper>
      )}
    </Fragment>
  );
};

export default PageFundDetail;
