// TODO: comment
import { Fade, Paper, CircularProgress, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './Splash.scss';
import { makeStyles } from '@material-ui/core/styles';
import { getPublicPath } from '../../utils/assets';

interface Props {
  fadeOut?: boolean;
}

const useStyles = makeStyles((theme) => ({
  img: {
    position: 'relative',
    overflow: 'hidden',
    width: 260,
    height: 56,
    backgroundImage: () => `url("${getPublicPath('images/abrdn.svg')}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
}));

export const Splash = ({ fadeOut }: Props) => {
  const [visible, setVisible] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (fadeOut) {
      setTimeout(() => {
        setVisible(false);
      }, 1500);
    }
  }, [fadeOut]);

  return (
    <Fade in={visible}>
      <Paper className="app-loader">
        <div className={classes.img} />
        <Box mt={4}>
          <CircularProgress />
        </Box>
      </Paper>
    </Fade>
  );
};
