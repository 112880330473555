import './MessageList.scss';

import { AttachFileIcon, DeleteIcon, PriorityHighIcon } from 'icons';
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  GetReceivedMessagesResponse,
  GetSentMessagesResponse,
  ReceivedMessage,
  SentMessage,
} from 'api/types';
import React, { Fragment, useEffect, useState } from 'react';
import { getMessagesPerPage, setMessagesPerPage } from 'api/constants';
import { lighten, makeStyles } from '@material-ui/core/styles';

import { Confirmation } from 'components/confirmation';
import { MESSAGE_BREAKER } from '@abrdn-latest/config';
import { MessagePageParams } from '../PageMessages';
import { Pagination } from 'components/pagination';
import clsx from 'clsx';
import { getFormattedDate } from 'utils';
import { isReceived } from '../utils';
import { selectDeletedIds } from 'redux/reducers/messages';
import { useMessageContext } from '../MessageContext';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

interface EnhancedTableHeadProps {
  canDelete: boolean;
  numSelected: number;
  onDelete(): void;
  onSelectAllClick(ev: any): void;
  rowCount: number;
}

export const EnhancedTableHead = ({
  onSelectAllClick,
  numSelected,
  rowCount,
  canDelete = true,
  onDelete = () => {},
}: EnhancedTableHeadProps) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {canDelete && (
        <Box padding="checkbox" style={{ marginLeft: -4, marginRight: 7 }}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all items' }}
          />
        </Box>
      )}

      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          Messages
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const trimBreaker = (str: string): string => {
  return String(str.split(MESSAGE_BREAKER)[0]);
};

function stableSort(array: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);

  return stabilizedThis.map((el: any) => el[0]);
}

interface MessageTableProps {
  data: GetSentMessagesResponse | GetReceivedMessagesResponse;
  onDelete(selected: Array<string>): void;
  onMessageClick(id: string): void;
  onPagination(page: number, rows: number): void;
  selectedId: string;
}

export const MessageList = ({
  data,
  onDelete,
  onPagination,
  onMessageClick,
  selectedId,
}: MessageTableProps) => {
  const { mailbox = 'inbox' }: MessagePageParams = useParams();

  const { actionOverrides } = useMessageContext();

  const deletedMessages = useSelector(selectDeletedIds);

  const [selected, setSelected] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(getMessagesPerPage());

  const [rows, setRows] = useState(data.messages);

  const handleSelectAllClick = (ev: any) => {
    if (ev.target.checked) {
      // @ts-ignore
      const newSelecteds = rows.map((n) => n.id);

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    if (data.messages && data.messages.length > 0) {
      // @ts-ignore
      const filtered = data.messages.filter((item) => {
        const isDeleted = deletedMessages.indexOf(item.id) > -1;

        return !isDeleted;
      });

      setRows(filtered);
    } else {
      setRows(data.messages);
    }
  }, [data, deletedMessages]);

  const [confirmMessageOpen, setConfirmMessageOpen] = useState(false);

  const handleDelete = () => {
    setConfirmMessageOpen(true);
    // onConfirm();
  };

  const onConfirm = () => {
    if (data.messages && data.messages.length > 0) {
      // @ts-ignore
      const filtered = data.messages.filter((item) => {
        const isDeleted = deletedMessages.indexOf(item.id) > -1;
        const isToBeDeleted = selected.indexOf(item.id) > -1;

        return !isDeleted && !isToBeDeleted;
      });

      const messageIdToDisplay = filtered.length ? filtered[0].id : undefined;
      onMessageClick(messageIdToDisplay);
    }

    onDelete(selected);
    setConfirmMessageOpen(false);
    setSelected([]);
  };
  const onCancel = () => {
    setConfirmMessageOpen(false);
  };

  const handleClick = (ev: React.SyntheticEvent, name: any) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const onPageChange = (items: number) => {
    setRowsPerPage(items);
    setPage(0);

    onPagination(0, items);

    setMessagesPerPage(items);
  };

  // @ts-ignore
  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      <Confirmation
        isOpen={confirmMessageOpen}
        confirmText="Yes, Delete"
        onConfirm={onConfirm}
        rejectText="No, cancel"
        onReject={onCancel}
      >
        <Typography paragraph>
          Are you sure you want to permanently delete <b>{selected.length}</b>{' '}
          messages
        </Typography>
      </Confirmation>
      <Paper className="messages-list">
        <Box>
          <EnhancedTableHead
            onDelete={handleDelete}
            canDelete={true}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />
          <Box>
            {stableSort(rows).map(
              (row: SentMessage | ReceivedMessage, index: number) => {
                const isItemSelected = isSelected(row.id);

                if (
                  mailbox === 'action-required' &&
                  actionOverrides[row.id] === false
                ) {
                  return null;
                }

                const isDeleted = deletedMessages.indexOf(row.id) > -1;

                const classes = clsx('message-item', {
                  unread: isReceived(row) && row.status === 'New',
                  deleted: isDeleted,
                  selected: isItemSelected,
                  highlight: selectedId === row.id,
                });

                return (
                  <Fragment key={row.id}>
                    <Collapse in={!isDeleted}>
                      <Box
                        onClick={(ev: React.SyntheticEvent) => {
                          if (ev.target && !isDeleted) {
                            //
                            if ((ev.target as Element).nodeName === 'INPUT') {
                              handleClick(ev, row.id);
                            } else {
                              onMessageClick(row.id);
                            }
                          }
                        }}
                        className={classes}
                      >
                        <Box
                          className="message-item__actions"
                          padding={1}
                          textAlign="center"
                        >
                          <Checkbox color="primary" checked={isItemSelected} />

                          {isReceived(row) &&
                          (row.actionRequired || actionOverrides[row.id]) &&
                          mailbox === 'inbox' ? (
                            <Box>
                              <PriorityHighIcon
                                className="priority"
                                fontSize="small"
                              />
                            </Box>
                          ) : null}

                          {row.hasDocuments ? (
                            <Box>
                              <AttachFileIcon />
                            </Box>
                          ) : null}
                        </Box>
                        <Box className="message-item__detail">
                          <Box className="message-item__source">
                            <b>{row.source.name}</b>

                            <span>
                              {isReceived(row)
                                ? getFormattedDate(row.received)
                                : getFormattedDate(row!.sent)}
                            </span>
                          </Box>
                          <Box paddingTop={1}>
                            <Typography variant="caption">
                              {row.subject}
                            </Typography>
                            <Typography variant="caption" display="block">
                              {trimBreaker(row.body).substr(0, 40)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      {index !== rows.length - 1 && <Divider />}
                    </Collapse>
                  </Fragment>
                );
              }
            )}
          </Box>
        </Box>
      </Paper>
      <Paper className="messages-pagination">
        <Pagination
          showSelector={false}
          onPagination={onPagination}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          startRowIndex={data.pagination.startRowIndex}
          maximumRows={data.pagination.maximumRows}
          minimumTotalRows={data.pagination.minimumTotalRows}
          totalRows={data.pagination.totalRows}
        />
      </Paper>
    </Fragment>
  );
};
