// TODO: comment
import { useRouter } from '@abrdn-latest/use';
import { AppBar, Drawer, IconButton, List, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ListItemLink } from 'components/navigation';
import { MenuIcon } from 'icons';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeDark } from 'styles';
import './HeaderMobile.scss';
import { PrimaryNavigation } from './Navigation';
import { getPublicPath } from '../../utils/assets';

// TODO: move this to scss
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },

  title: {
    display: 'block',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  logo: {
    position: 'relative',
    overflow: 'hidden',
    width: 92,
    height: 20,
    backgroundImage: () => `url("${getPublicPath('images/abrdn.svg')}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },

  sectionDesktop: {
    display: 'flex',
  },

  list: {
    width: 320,
  },
}));

export const HeaderMobile = () => {
  const { t } = useTranslation(['common']);

  const classes = useStyles();

  const router = useRouter();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (ev: any) => {
    if (ev.type === 'keydown' && (ev.key === 'Tab' || ev.key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
  };

  const handleLogout = async (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    router.replace('/logout');
  };

  return (
    <ThemeDark>
      <Fragment>
        <div className={classes.grow}>
          <AppBar position="static">
            <Toolbar>
              <div className={classes.logo} />
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </div>
        <Drawer
          anchor="right"
          className="mobile-drawer"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          <div
            className={clsx(classes.list, 'mobile-drawer__list')}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <nav className="nav nav--main nav--mobile">
              <PrimaryNavigation isMobile />
              <List className="nav--mobile__account">
                <ListItemLink to="/account" primary={t('nav.account')} />
                <ListItemLink
                  to="/logout"
                  primary={t('nav.logout')}
                  onClick={handleLogout}
                />
              </List>
            </nav>
          </div>
        </Drawer>
      </Fragment>
    </ThemeDark>
  );
};
