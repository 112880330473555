// TODO: comment
import { Box, Container, Paper, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { NewMessageForm } from './components';

const PageNewMessage = () => {
  const { t } = useTranslation(['common']);
  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.new-message')}</title>
      </Helmet>

      <Box paddingY={6}>
        <Container maxWidth="sm">
          <Paper>
            <Box padding={4}>
              <Box paddingBottom={2}>
                <Typography variant="h3" component="h1">
                  {t('common:titles.new-message')}
                </Typography>
              </Box>
              <NewMessageForm />
            </Box>
          </Paper>
        </Container>
      </Box>
    </Fragment>
  );
};

export default PageNewMessage;
