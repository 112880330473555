import { useEffect } from 'react';
import { useSecurity } from './SecurityContext';
import { Splash } from '../components/splash';
import { useRouter } from '../@abrdn-latest/use';
import { useOktaAuth } from '@okta/okta-react';

export function LogoutPage() {
  const { authMethods } = useSecurity();
  const { authState: oktaAuthState } = useOktaAuth();
  const { query } = useRouter();
  const oktaBaseUrl = new URL(oktaAuthState.idToken.issuer).origin;
  const resetPasswordUrl = `${oktaBaseUrl}/signin/forgot-password`;

  useEffect(() => {
    async function logout() {
      try {
        await authMethods.signOut();
        // @ts-ignore
        if (query.resetpassword) {
          window.location.href = resetPasswordUrl;
        }
      } catch (err: any) {
        // NOTE: Can't distinguish CORS error from other network errors
        const isCorsError = !err.errorCode &&
          err.name === 'AuthApiError' &&
          err.xhr.message === 'Failed to fetch';
        if (isCorsError) {
          // TODO: handle cors
        } else {
          throw err;
        }
      }
    }

    logout();
  }, []);

  return <Splash />;
}
