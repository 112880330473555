// TODO: comment
import { AbrdnBox } from '@abrdn';
import {
  BrandColours,
  DEFAULT_CURRENCY_CODE,
  GREY_LIGHT,
} from '@abrdn-latest/config';
import { LineClamp } from '@abrdn-latest/core';
import { FundTypes } from '@abrdn-latest/types';
import { useDeepCompareEffect, useDidMountEffect } from '@abrdn-latest/use';
import {
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import { getClientSummary } from 'api';
import { ClientAccountSummary, Fund, Party } from 'api/types';
import { Anchor } from 'components/core';
import { CurrencySelector } from 'components/form';
import {
  DocumentOnlyClientIcon,
  ExternalLink,
  InfoOutlinedIcon,
  StarIcon,
  UnfoldLessIcon,
  UnfoldMoreIcon,
} from 'icons';
import { useClientHoldings } from 'pages/client-holdings/data';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formatNumber, getFormattedDate } from 'utils';
import { getUniqueCurrencies } from '../getUniqueCurrencies';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: GREY_LIGHT,
  },
}));

interface Props {
  client?: Party;
  expanded?: boolean;
  onMoreDetails?(): void;
}

export const Tile = ({ expanded = false, client, onMoreDetails }: Props) => {
  const content = {
    info: {
      title: `Valuations`,
      copy: `This is a summary view of your holdings in the named account. The value represented here is an estimation based on latest current available data and FX rates. To view more detailed information select View Accounts from the holding or select the Accounts tab at the top of the page`,
    },
  };

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [detail, setDetail] = useState<ClientAccountSummary | null>(null);
  const [error, setError] = useState<boolean>(false);

  const handleClick = (ev: any) => {
    setAnchorEl(anchorEl ? null : ev.currentTarget);
  };

  const handleClickAway = (ev: any) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  // client holdings methods
  const { getClientDetails } = useClientHoldings();

  const [currencyCode, setCurrencyCode] = useState<string | null>(null);
  const [valuation, setValuation] = useState<number | null>(null);

  // when the component loads or the client changes, we need to get
  // all the summary information
  useDeepCompareEffect(() => {
    // load the summary/detailed information
    const load = async () => {
      if (client) {
        const detail = await getClientDetails(client.id);

        // TODO: work out why this is erroring
        if (detail === null) {
          if (process.env.NODE_ENV === 'development') {
          }
          setError(true);
        } else {
          // setError(false);
        }

        setCurrencyCode(null);

        setDetail(detail);

        if (detail) {
          setValuation(detail.valuation.value);
        }
      }
    };

    load();
  }, [client]);

  // toggle additional account details open/closed
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const toggleDetail = () => {
    setCollapsed(!collapsed);
  };

  useDidMountEffect(() => {
    const load = async () => {
      try {
        if (client) {
          const summary = await getClientSummary(client.id, null, currencyCode);

          if (summary) {
            setValuation(summary.valuation.value);
          }
        }
      } catch (e) {
        setError(true);
      }
    };

    load();
  }, [currencyCode]);

  if (error) {
    return (
      <AbrdnBox elevation={1}>
        <Box minHeight={142}>
          {client && (
            <Typography
              variant="h5"
              style={{ minHeight: 42, marginBottom: 15 }}
            >
              <LineClamp count={2}>{client.preferredName}</LineClamp>
            </Typography>
          )}
          <Typography variant="h6">
            There has been an issue loading this account
          </Typography>
        </Box>
      </AbrdnBox>
    );
  }

  if (expanded) {
    // get the list of currencies
    const currencies: string[] = detail
      ? detail.accounts.map((account) => account.valuation.currencyCode)
      : [];

    if (detail) {
      currencies.unshift(detail.valuation.currencyCode);
    }

    // check to see if SICAV is in the list
    const hasSICAV =
      (detail
        ? detail.accounts.filter(
            (account) => account.productType === FundTypes.SICAV
          )
        : []
      ).length > 0;

    if (hasSICAV) {
      currencies.push('EUR');
    }

    return (
      <AbrdnBox elevation={1} padding={0}>
        <Paper className={classes.paper}>
          <Box padding={3}>
            <Grid container spacing={3} direction="row" justify="space-between">
              <Grid item>
                <Typography variant="h5">
                  {client?.preferredName ? (
                    <Anchor to={`/accounts/${client.id}/accounts/`}>
                      {client.preferredName}
                    </Anchor>
                  ) : (
                    <Skeleton width={180} />
                  )}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton
                  color="primary"
                  aria-label="Information"
                  size="small"
                  onClick={handleClick}
                >
                  <InfoOutlinedIcon />
                </IconButton>

                <Popper id={id} open={open} anchorEl={anchorEl} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Paper elevation={1}>
                          <Box padding={2} maxWidth={450}>
                            <Typography>{content.info.title}</Typography>
                            <Typography variant="body2">
                              {content.info.copy}
                            </Typography>
                          </Box>
                        </Paper>
                      </ClickAwayListener>
                    </Fade>
                  )}
                </Popper>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h4" style={{ margin: 0 }}>
                  {detail && valuation ? (
                    formatNumber(valuation, DEFAULT_CURRENCY_CODE, 2)
                  ) : (
                    <Skeleton width={180} />
                  )}
                </Typography>
              </Grid>
              {detail && (
                <Grid item>
                  <CurrencySelector
                    currencies={(() => {
                      return [
                        ...new Set([
                          detail.valuation.currencyCode,
                          ...detail.currencyCodes,
                        ]),
                      ];
                    })()}
                    onChange={(currencyCode: string) => {
                      setCurrencyCode(currencyCode);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>

        <Divider />

        {detail && detail.accounts.length && (
          <Collapse in={!collapsed}>
            <Box padding={3}>
              <TableContainer component={Paper}>
                <Table size="small" className="noborder">
                  <TableHead>
                    <TableRow>
                      <TableCell>Account number</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Account value</TableCell>
                      <TableCell style={{ width: 40 }}></TableCell>
                      <TableCell>As of</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detail.accounts.map((account, index: number) => {
                      return (
                        <TableRow key={`a-${index}`}>
                          <TableCell>{account.accountNumber}</TableCell>
                          <TableCell>{account.productType}</TableCell>
                          <TableCell align="right">
                            {formatNumber(
                              account.valuation.value,
                              DEFAULT_CURRENCY_CODE,
                              2
                            )}
                          </TableCell>
                          <TableCell>
                            {account.valuation.currencyCode}
                          </TableCell>
                          <TableCell>
                            {getFormattedDate(account.valuation.date)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        )}

        <Divider />

        <Box padding={3}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              {detail && detail.accounts && detail.accounts.length && (
                <Button
                  size="small"
                  onClick={toggleDetail}
                  startIcon={
                    collapsed ? <UnfoldMoreIcon /> : <UnfoldLessIcon />
                  }
                >
                  {collapsed ? `Show Details` : `Hide Details`}
                </Button>
              )}
            </Grid>
            <Grid item>
              {client && (
                <Anchor to={`/accounts/${client.id}/accounts/`}>
                  View Accounts{' '}
                  <ExternalLink style={{ fontSize: 24, marginLeft: 6 }} />
                </Anchor>
              )}
            </Grid>
          </Grid>
        </Box>
      </AbrdnBox>
    );
  }

  return (
    <AbrdnBox elevation={1}>
      <Typography variant="h5" style={{ minHeight: 42, marginBottom: 15 }}>
        {client ? (
          <Anchor
            to={`/accounts/${client.id}/accounts/`}
            onClick={(ev: any) => {
              ev.preventDefault();
              if (onMoreDetails) {
                onMoreDetails();
              }
            }}
          >
            <LineClamp count={2}>{client.preferredName}</LineClamp>
          </Anchor>
        ) : (
          <Skeleton width={180} />
        )}
      </Typography>
      <Box paddingBottom={2}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Box
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 175,
              }}
            >
              <Typography variant="h5" noWrap style={{ margin: 0 }}>
                {detail ? (
                  formatNumber(
                    detail.valuation.value,
                    detail.valuation.currencyCode,
                    2
                  )
                ) : (
                  <Skeleton width={180} />
                )}
              </Typography>
            </Box>
          </Grid>
          {detail && (
            <Grid item>
              <CurrencySelector
                currencies={
                  getUniqueCurrencies(detail.accounts)[0]
                    ? [getUniqueCurrencies(detail.accounts)[0]]
                    : [DEFAULT_CURRENCY_CODE]
                }
              />
            </Grid>
          )}
        </Grid>
      </Box>
      {onMoreDetails && client && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          to={`/accounts/${client.id}/accounts/`}
          component={RouterLink}
        >
          View Accounts
        </Button>
      )}
    </AbrdnBox>
  );
};

export const DocumentOnlyTile = ({ client }: { client: Party }) => {
  const { id, preferredName } = client;
  const link = { pathname: '/documents', state: { clientId: id } };
  return (
    <AbrdnBox elevation={1}>
      <Typography variant="h5" style={{ minHeight: 42, marginBottom: 15 }}>
        <Anchor to={link}>
          <LineClamp count={2}>{preferredName}</LineClamp>
        </Anchor>
      </Typography>
      <Box paddingBottom={2}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Box
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 175,
              }}
            >
              <DocumentOnlyClientIcon />
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="h5" noWrap style={{ margin: 0, color: BrandColours[6] }}>
              Document Library
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        to={link}
        component={RouterLink}
      >
        View Documents
      </Button>
    </AbrdnBox>
  );
};

export const FavouriteFundTile = ({ fund }: { fund: Fund }) => {
  return (
    <AbrdnBox elevation={1}>
      <Typography variant="h5" style={{ minHeight: 42, marginBottom: 15 }}>
        <Anchor to={`/portfolio-holdings/${fund.fundCode}`}>
          <LineClamp count={2}>{fund.fundName}</LineClamp>
        </Anchor>
      </Typography>
      <Box paddingBottom={2}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Box
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 175,
              }}
            >
              <StarIcon style={{ color: BrandColours[2] }} />
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="h5" noWrap style={{ margin: 0, color: BrandColours[2] }}>
              Favourite Fund
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        to={`/portfolio-holdings/${fund.fundCode}`}
        component={RouterLink}
      >
        View Details
      </Button>
    </AbrdnBox>
  );
};
