import { Balls } from '@abrdn';
import {
  ApplePodcastsIcon,
  ESGPodcastIcon,
  ESGReportIcon,
  SpotifyIcon,
} from 'icons';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { Stack } from '@abrdn-latest/core';
import { useWidth } from '@abrdn-latest/use';
import { useApp } from '@abrdn-latest/providers';

import { GREY_MEDIUM } from '@abrdn-latest/config';
import React from 'react';

const handlePodcastClick = (ev: React.MouseEvent) => {
  ev.preventDefault();
};

const handleReportClick = (ev: React.MouseEvent) => {
  ev.preventDefault();
};

// copy
const content = {
  header: {
    title: 'Sustainable Investing',
    subtitle: 'Invest today. Change tomorrow.',
    subsubtitle: 'ESG factors at the heart of our investment processes',
  },
  video: {
    title: 'Ethical, Social and Governance',
    src: 'https://aberdeen-asset.kulu.net/view/qcNHlWDvy6P?inline=true',
  },
  items: [
    {
      title: 'Sustainability Inspires Podcast',
      copy: 'Explore Sustainable Investing themes such as ESG, sustainability, and climate change in thought-provoking discussions on how we can invest for a better future.',
      copyMaxWidth: 430,

      cta: (
        <Stack spacing={2}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            target="_blank"
            href={
              'https://podcasts.apple.com/gb/podcast/sustainability-inspires-podcast/id1560985640#xd_co_f=OGIzMTg4YTItNGE0Zi00NTQ5LTg3NTgtOGI4N2ViMzYyNmI5~'
            }
            startIcon={<ApplePodcastsIcon />}
            style={{ width: 178 }}
          >
            Apple Podcasts
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="large"
            target="_blank"
            href={'https://open.spotify.com/show/0IUZ5zx3DYQYcGtNQA11Xd'}
            startIcon={<SpotifyIcon />}
            style={{ width: 178 }}
          >
            Spotify
          </Button>
        </Stack>
      ),
      icon: <ESGPodcastIcon style={{ fontSize: 60 }} />,
    },
    {
      title: 'Stewardship Report',
      copy: 'Stewardship is the responsible allocation, management, and oversight of capital to create long-term value for clients and beneficiaries leading to sustainable benefits for the economy, the environment and society.',
      copyMaxWidth: 410,

      cta: (
        <Button
          variant="contained"
          color="primary"
          size="large"
          target="_blank"
          href={
            'https://www.abrdn.com/docs?editionId=50636955-103f-47cb-86e2-036aec4d30d4'
          }
        >
          Read the Report
        </Button>
      ),

      icon: <ESGReportIcon style={{ fontSize: 60 }} />,
    },
  ],
};

/**
 *
 * @returns ReactNode
 */
export const EthicalSocialGovernance = () => {
  const { isMobile } = useWidth();
  const { isHomepage = false } = useApp();

  return (
    <Paper>
      <Container>
        <Box paddingY={8}>
          {/* Title/Header area */}
          <Grid container spacing={3} justify="space-between">
            <Grid item>
              {isHomepage && (
                <Typography variant="h3" style={{ marginBottom: 40 }}>
                  {content.header.title}
                </Typography>
              )}
              <Typography variant="h4" style={{ margin: 0, marginBottom: 16 }}>
                {content.header.subtitle}
              </Typography>
              <Typography variant="subtitle2" style={{ marginBottom: 40 }}>
                {content.header.subsubtitle}
              </Typography>
            </Grid>
            {/* If the page is big enough, add the ball graphic */}
            {!isMobile && isHomepage && (
              <Grid item>
                <Balls cols={13} rows={5} />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={3} wrap="wrap">
            {content.items.map(({ title, copy, copyMaxWidth, icon = null, cta = null }, index: number) => (
              <Grid
                item
                xs={12}
                md={6}
                key={`i-${index}`}
              >
                <Box p={3} pt={4} style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  backgroundColor: GREY_MEDIUM,
                  height: '100%',
                }}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography variant="h4" style={{ marginBottom: 16 }}>
                        {title}
                      </Typography>

                      <Typography paragraph style={{ fontSize: 14, maxWidth: copyMaxWidth }}>
                        {copy}
                      </Typography>
                    </Grid>

                    <Grid item>
                      {icon}
                    </Grid>
                  </Grid>

                  <Box mt={3}>
                    {cta}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Paper>
  );
};
