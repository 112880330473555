// TODO: comment

import { AccountDetailAccordion, Exporter } from './components';
import {
  Box,
  Button,
  Collapse,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { ExportButton, InfoPopper } from 'components/ui';
import { Fragment, useEffect, useState } from 'react';
import { ThemeDark, ThemeDefault } from 'styles';
import { formatNumber, getDateFormat } from 'utils';
import {
  getClientAccountAccountsDownloadUri,
  getClientAccountTransactionsDownloadUri,
} from 'api';
import { useDeepCompareEffect, useRouter, useWidth } from '@abrdn-latest/use';

import { AdditionalInfo } from 'pages/portfolio-holdings/components';
import { Alert } from '@material-ui/lab';
import { Banner } from '@abrdn';
import { ClientAccountSummary } from 'api/types';
import { CurrencySelector } from 'components/form';
import { DatePicker } from '@material-ui/pickers';
import { GREY_DARK } from '@abrdn-latest/config';
import { Helmet } from 'react-helmet';
import { Loading } from 'components/no-data';
import { downloadFile } from 'api/utils';
import { useClientHoldings } from 'pages/client-holdings/data';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPublicPath } from '../../utils/assets';

enum ViewType {
  Account = 'accounts',
  Transaction = 'transactions',
}

interface Params {
  /**
   *
   */
  client: string;

  /**
   *
   */
  section?: string;
}

type View = ViewType.Account | ViewType.Transaction;

interface TransactionRange {
  /**
   *
   */
  end: Date;

  /**
   *
   */
  start: Date;
}

/**
 *
 * @returns
 */
const PageAccount = () => {
  // translations
  const { t } = useTranslation(['common', 'client']);

  //
  const today: Date = new Date();

  const TWO_YEARS_AGO = new Date(
    today.getFullYear() - 2,
    today.getMonth(),
    today.getDate()
  );

  const [minFundDate, setMinFundDate] = useState<Date | null>(TWO_YEARS_AGO);

  // get the current date
  const now: Date = new Date();

  const current = new Date();

  const YESTERDAY = new Date(current.setDate(current.getDate() - 1));

  const [maxFundDate, setMaxFundDate] = useState<Date | null>(YESTERDAY);

  // store the default start and end dates
  const transactionDateDefaults: TransactionRange = {
    start: new Date(now.setMonth(now.getMonth() - 1)),
    end: new Date(),
  };

  // transaction date range
  const [transactionRange, setTransactionRange] = useState<TransactionRange>(
    transactionDateDefaults
  );

  // get the client holding data
  const { clients, getClientHoldingSummary, clientCurrencies } =
    useClientHoldings();

  // mobile viewport width check
  const { isSmall, isXSmall } = useWidth();

  // get the possible routes
  const params: Params = useParams();

  // the client
  const [client, setClient] = useState<string>(params.client || '');
  const [summary, setSummary] = useState<ClientAccountSummary | null>(null);
  const [hasValue, setHasValue] = useState<boolean>(true);

  const [currencyCode, setCurrencyCode] = useState<string | null>(null);

  const [valuationDate, setValuationDate] = useState<Date | null | undefined>(
    null
  );

  const handleDateChange = (e: any) => {
    const converted = e ? e.toDate() : e;

    if (valuationDate !== converted) {
      setValuationDate(converted);
    }
  };

  const handleTransactionStartDateChange = (e: any) => {
    setTransactionRange({ ...transactionRange, ...{ start: e.toDate() } });
  };

  const handleTransactionEndDateChange = (e: any) => {
    setTransactionRange({ ...transactionRange, ...{ end: e.toDate() } });
  };

  useEffect(() => {
    setTransactionRange(transactionDateDefaults);
  }, [client]);

  useEffect(() => {
    const load = async () => {
      try {
        const summary = await getClientHoldingSummary(
          client,
          valuationDate,
          currencyCode
        );

        if (summary) {
          setSummary(summary);

          const accounts = summary.accounts.filter((account) => {
            return !account.dataIssue;
          });

          if (accounts.length) {
            // loop through all the accounts to compare the minumim date
            // get the minumum date
            const minimumDate = accounts
              .map(({ minimumValuationDate }) => minimumValuationDate)
              .reduce((a, b) => {
                return a && new Date(a) < new Date(b) ? a : b;
              }, '');

            // get the maximum date
            const maximumDate = accounts
              .map(({ maximumValuationDate }) => maximumValuationDate)
              .reduce((a, b) => {
                return a && new Date(a) > new Date(b) ? a : b;
              }, '');

            setMinFundDate(new Date(minimumDate));
            setMaxFundDate(new Date(maximumDate));
          }

          if (summary.valuation.date) {
            setValuationDate(new Date(summary.valuation.date));
          }
        }
      } catch (e) {
        setHasValue(false);
      }
    };

    load();
  }, [client, currencyCode]);

  useEffect(() => {
    if (summary) {
      setHasValue(true);
    }
  }, [summary]);

  useDeepCompareEffect(() => {
    const load = async () => {
      try {
        const summary = await getClientHoldingSummary(
          client,
          valuationDate,
          currencyCode
        );

        if (summary) {
          setSummary(summary);
        }
      } catch (e) {
        setHasValue(false);
      }
    };

    load();
  }, [valuationDate]);

  // the view
  const [view, setView] = useState<View>(ViewType.Account);

  const router = useRouter();

  const menuItems = [
    {
      id: ViewType.Account,
      label: t('client:navigation.valuations'),
    },
    {
      id: ViewType.Transaction,
      label: t('client:navigation.transactions'),
    },
  ];

  const getClientName = (): string => {
    const filtered = clients.filter((item) => {
      return item.id === client;
    });

    if (filtered && filtered.length > 0) {
      return filtered[0].preferredName;
    }

    return '';
  };

  useEffect(() => {
    router.history.replace(`/accounts/${client}/${view}`);
  }, [view]);

  const handleDownload = () => {
    downloadFile(
      getClientAccountAccountsDownloadUri(
        client,
        undefined,
        valuationDate,
        currencyCode || ''
      ),
      `${getClientName()}-accounts.xlsx`
    );
  };

  const handleDownloadTransactions = (type: string) => {
    const start = getDateFormat(transactionRange.start);
    const end = getDateFormat(transactionRange.end);

    downloadFile(
      getClientAccountTransactionsDownloadUri(
        client,
        null,
        transactionRange.start,
        transactionRange.end,
        type
      ),
      `${getClientName()}-transactions (${start}-${end}).${type.toLowerCase()}`
    );
  };

  const handleClientChange = (ev: any) => {
    const value: string = ev.target.value;

    if (client !== value) {
      router.push(`/accounts/${value}/${view}`);

      setSummary(null);
      setValuationDate(null);
      setClient(value);
    }
  };

  if (clients && clients.length > 0 && !params.client) {
    router.history.replace(`/accounts/${clients[0].id}/accounts`);
  }

  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.accounts')}</title>
      </Helmet>

      <Banner imagePath={getPublicPath('images/backgrounds/client-holdings.jpg')}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Typography variant="h3" component="h1">
              {t('common:titles.client-holdings')}
            </Typography>
          </Grid>
          <Grid item>
            <ThemeDefault>
              <AdditionalInfo>
                {view === ViewType.Account && <AccountHelp />}
                {view === ViewType.Transaction && <TransactionsHelp />}
              </AdditionalInfo>
            </ThemeDefault>
          </Grid>
        </Grid>

        {/* */}
        {clients && (
          <Box marginBottom={4}>
            {clients.length === 1 ? (
              <Typography component="h2" variant="h4">
                {clients[0].preferredName}
              </Typography>
            ) : (
              <ThemeDefault>
                <FormControl
                  variant="outlined"
                  style={{ maxWidth: 320, width: '100%' }}
                >
                  <Select
                    fullWidth
                    value={client}
                    onChange={handleClientChange}
                    className="abrdn-select-dark"
                    MenuProps={{
                      PaperProps: {
                        className: 'abrdn-dropdown',
                      },
                    }}
                  >
                    {clients.map((client, index: number) => {
                      return (
                        <MenuItem key={`o-${index}`} value={client.id}>
                          {client.preferredName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </ThemeDefault>
            )}
          </Box>
        )}

        {/* Navigation */}
        {summary && (
          <Fragment>
            {menuItems.map((item, index: number) => {
              const Theme = view === item.id ? ThemeDark : ThemeDefault;

              return (
                <Theme key={`mi-${index}`}>
                  <Button
                    key={item.id}
                    style={{ marginRight: 10, border: '1px solid #fff' }}
                    size="large"
                    onClick={() => {
                      setView(item.id as View);
                    }}
                    variant={view === item.id ? 'contained' : 'contained'}
                    color="primary"
                  >
                    {item.label}
                  </Button>
                </Theme>
              );
            })}
          </Fragment>
        )}
      </Banner>

      <Paper style={{ backgroundColor: GREY_DARK }}>
        <Box paddingY={6}>
          {/* loading state/indicator */}
          {!clients ||
            (!summary && (
              <Container>
                {hasValue ? (
                  <Loading>
                    <Typography paragraph>Loading accounts</Typography>
                  </Loading>
                ) : (
                  <Alert severity="info">
                    There has been an error loading this account
                  </Alert>
                )}
              </Container>
            ))}

          {/* if accounts have been set */}
          {summary && (
            <Collapse
              in={view === ViewType.Account || view === ViewType.Transaction}
            >
              <Fragment>
                <Collapse in={view === ViewType.Account}>
                  {/*<AccountFilters*/}
                  {/*  currencies={clientCurrencies[client] || []}*/}
                  {/*  onCurrencyChange={setCurrencyCode}*/}
                  {/*  estimatedValue={*/}
                  {/*    hasValue*/}
                  {/*      ? formatNumber(*/}
                  {/*        summary.valuation.value,*/}
                  {/*        summary.valuation.currencyCode*/}
                  {/*      )*/}
                  {/*      : '-'*/}
                  {/*  }*/}
                  {/*  datePickerProps={{*/}
                  {/*    value: valuationDate,*/}
                  {/*    minDate: minFundDate,*/}
                  {/*    maxDate: maxFundDate,*/}
                  {/*    onAccept: handleDateChange,*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <Container>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justify="space-between"
                      component="dl"
                      className="infolist"
                    >
                      <Grid item xs={12} sm={4}>
                        <Typography component="dt">
                          <InfoPopper
                            title="Valuation date"
                            content="You can change the valuation date by selecting from the dropdown menu of available valuation dates."
                          />
                        </Typography>
                        <dd>
                          <DatePicker
                            className={'abrdn-picker'}
                            autoOk
                            disableFuture
                            value={valuationDate}
                            format="DD MMMM YYYY"
                            minDate={minFundDate}
                            maxDate={maxFundDate}
                            emptyLabel="Latest"
                            clearable
                            clearLabel="Latest valuation"
                            onChange={() => {}}
                            onAccept={handleDateChange}
                          />
                        </dd>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography component="dt">
                          View full details
                        </Typography>
                        <dd>
                          {hasValue ? (
                            <ExportButton
                              label={`Download`}
                              onClick={handleDownload}
                            />
                          ) : (
                            '-'
                          )}
                        </dd>
                      </Grid>
                      <Grid item xs={12} sm={4} className="align-right">
                        <Typography component="dt">Estimated value</Typography>
                        <dd>
                          <CurrencySelector
                            currencies={clientCurrencies[client] || []}
                            onChange={(currencyCode: string) => {
                              setCurrencyCode(currencyCode);
                            }}
                          />{' '}
                          <Typography component="span">
                            {hasValue
                              ? formatNumber(
                                summary.valuation.value,
                                summary.valuation.currencyCode
                              )
                              : '-'}
                          </Typography>
                        </dd>
                      </Grid>
                    </Grid>
                  </Container>
                </Collapse>

                <Collapse in={view === ViewType.Transaction}>
                  <Container>
                    <Typography variant="h4">
                      Summary of transactions
                    </Typography>

                    <Grid container direction="row" spacing={4}>
                      <Grid item xs={12} md={6}>
                        {/* date range */}
                        <Box marginY={1}>
                          <Typography variant="h5" style={{ marginBottom: 10 }}>
                            Date range
                          </Typography>

                          <Grid container direction="row" spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <DatePicker
                                className={'abrdn-picker'}
                                autoOk
                                disableFuture
                                value={transactionRange.start}
                                format="DD MMM YYYY"
                                maxDate={transactionRange.end}
                                minDate={minFundDate}
                                onChange={() => {}}
                                onAccept={handleTransactionStartDateChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <DatePicker
                                className={'abrdn-picker'}
                                autoOk
                                disableFuture
                                value={transactionRange.end}
                                format="DD MMM YYYY"
                                minDate={transactionRange.start}
                                maxDate={new Date()}
                                onChange={() => {}}
                                onAccept={handleTransactionEndDateChange}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {/* download */}
                        <Box marginY={1}>
                          <Grid
                            container
                            direction="row"
                            spacing={1}
                            justify="space-between"
                          >
                            <Grid item xs={6} md={12}>
                              <Typography
                                variant="h5"
                                style={{ marginBottom: 10 }}
                              >
                                View full details
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              md={12}
                              style={{
                                textAlign: isSmall ? 'right' : 'left',
                              }}
                            >
                              <Exporter onSelect={handleDownloadTransactions} />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </Collapse>

                <Box paddingY={6}>
                  <Container>
                    {summary.accounts.map((account, index: number) => (
                      <AccountDetailAccordion
                        order={index}
                        key={`${account.id}-${index}`}
                        clientId={client}
                        accountId={account.id}
                        open={index < 3}
                        valuationDate={valuationDate}
                        transactionRange={transactionRange}
                        overview={view === ViewType.Account}
                      />
                    ))}
                  </Container>
                </Box>
              </Fragment>
            </Collapse>
          )}
        </Box>
      </Paper>
    </Fragment>
  );
};

const AccountHelp = () => {
  const { t } = useTranslation('common');
  return (
    <Fragment>
      <Typography paragraph>
        {t('client:accounts.info.row1')}

      </Typography>
      <Typography paragraph style={{ fontWeight: 'bold' }}>
        {t('client:accounts.info.row2')}
      </Typography>
      <Typography paragraph>
        {t('client:accounts.info.row3')}
      </Typography>
      <Typography paragraph>
        {t('client:accounts.info.row4')}
      </Typography>
      <Typography paragraph>
        {t('client:accounts.info.row5')}
      </Typography>
      <Typography paragraph>
        {t('client:accounts.info.row6')}
      </Typography>
      <Typography paragraph>
        {t('client:accounts.info.row7')}
      </Typography>
      <Typography paragraph>
        {t('client:accounts.info.row8')}
      </Typography>
    </Fragment>
  );
}

const TransactionsHelp = () => (
  <Fragment>
    <Typography paragraph>
      This page displays account level transactions.
    </Typography>
    <Typography paragraph>
      In the Accounts Search panel you can select the holdings that you are
      associated with.
    </Typography>
    <Typography paragraph>
      You can move between the Accounts and Transactions view by selecting the
      buttons below the holdings selection dropdown.
    </Typography>
    <Typography paragraph>
      You can change the transaction date range by selecting from the dropdown
      and providing the dates required.
    </Typography>
    <Typography paragraph>
      You can export the full transaction details in excel format using the
      download function.
    </Typography>
  </Fragment>
);

export default PageAccount;
