// TODO: comment
import { useWidth } from '@abrdn-latest/use';
import { useSecurity } from 'authentication';
import React, { useEffect, useState } from 'react';
import { HeaderDesktop } from './HeaderDesktop';
import { HeaderMobile } from './HeaderMobile';

export const Header = () => {
  const { isDesktop } = useWidth();

  const { authState } = useSecurity();

  const [authenticated, setAuthenticated] = useState(
    authState.isAuthenticated
  );

  useEffect(() => {
    setAuthenticated(authState.isAuthenticated);
  }, [authState.isAuthenticated]);

  if (!authenticated) {
    return null;
  }

  return isDesktop ? <HeaderDesktop /> : <HeaderMobile />;
};
