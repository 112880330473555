import { AppProviderWrapper } from '@abrdn-latest/providers';
import DayJsUtils from '@date-io/dayjs';
import { Box, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Security, useSecurity } from 'authentication';
import { ActionRequiredModal } from 'components/action-required';
import { Connectivity } from 'components/connectivity';
import { ErrorBoundary } from 'components/error-boundary';
import { Footer } from 'components/footer';
import { Header } from 'components/header';
import { ScrollToTop } from 'components/scroll-to-top';
import { Unauthorized } from 'components/unauthorized';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { ClientHoldings } from 'pages/client-holdings/data';
import { MessagePopup } from 'pages/messages/components';
import { PortfolioHoldings } from 'pages/portfolio-holdings/data';
import React from 'react';
import { Helmet } from 'react-helmet';
import { HashRouter, BrowserRouter, Route } from 'react-router-dom';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper.scss';
import { getPublicPath } from './utils/assets';
import { LoginCallback } from '@okta/okta-react';
import { OktaSecurity } from './authentication/OktaSecurity';
import { Routes } from './pages/Routes';

const CookieConfig = () => {
  const { settings } = useSecurity();

  return (
    <Helmet>
      <script
        type="text/javascript"
        src={`https://cdn-ukwest.onetrust.com/consent/${settings['OneTrustID']}/OtAutoBlock.js`}
      ></script>
      <script
        src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        // @ts-ignore
        charset="UTF-8"
        data-domain-script={settings['OneTrustID']}
      ></script>

      <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
    </Helmet>
  );
};

// New
const AppMain = () => {
  return (
    <AppProviderWrapper>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <Security>
          <Unauthorized />
          <CookieConfig />
          <ClientHoldings>
            <PortfolioHoldings>
              <ScrollToTop />
              <Header />
              <Box className="main">
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
              </Box>
              <ActionRequiredModal />
              <MessagePopup />
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
              />

              <Footer />
            </PortfolioHoldings>
          </ClientHoldings>
        </Security>
      </MuiPickersUtilsProvider>
    </AppProviderWrapper>
  );
};

function App() {
  return (
    <Box className="app">
      <CssBaseline />
      <Helmet titleTemplate={`abrdn Connect - %s`}>
        <link
          rel="icon"
          type="image/x-icon"
          href={getPublicPath('favicon.ico')}
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={getPublicPath('apple-touch-icon.png')}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={getPublicPath('favicon-32x32.png')}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={getPublicPath('favicon-16x16.png')}
        />
        <link
          rel="mask-icon"
          href={getPublicPath('safari-pinned-tab.svg')}
          color="#5BBAD5"
        />
      </Helmet>
      <Connectivity />
      <OktaSecurity>
        <HashRouter>
          <AppMain />
        </HashRouter>
        <BrowserRouter>
          <Route path="*" component={LoginCallback} />
        </BrowserRouter>
      </OktaSecurity>
    </Box>
  );
}

export default App;
