// TODO: comment

import {
  ArrowBackIcon,
  DeleteIcon,
  MailIcon,
  PriorityHighIcon,
  RefreshIcon,
} from 'icons';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DataError, Loading, NoData } from 'components/no-data';
import { DocumentList, ReplyForm } from '.';
import { isReceived } from '../utils';
import {
  MESSAGE_BREAKER,
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '@abrdn-latest/config';
import React, { Fragment, useEffect, useState } from 'react';
import {
  GetReceivedMessagesResponse,
  GetSentMessagesResponse,
  ReceivedMessage,
  SentMessage,
} from 'api/types';
import {
  addDeletedMessageId,
  getUnreadCount,
  selectDeletedIds,
} from 'redux/reducers/messages';
import { delayFire, getSpecificFormattedDate } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter, useWidth } from '@abrdn-latest/use';

import { Alert } from '@material-ui/lab';
import { Anchor } from 'components/core';
import { Confirmation } from 'components/confirmation';
import { Helmet } from 'react-helmet';
import { SERVER_ACTION_DELAY } from 'api/constants';
import { Stack } from '@abrdn-latest/core';
import { setActionRequired } from 'api';
import { toast } from 'material-react-toastify';
import { useMessageContext } from '../MessageContext';
import { useSecurity } from 'authentication';

export interface IndividualMessageBaseProps {
  /**
   * Used for redirecting the user, and implementation of the 'back' button
   */
  baseUrl?: string;
  /**
   * Can the user reply to the message
   */
  canReply?: boolean;
  /**
   * Method used to make the api call to delete the message
   * @param id Message ID
   */
  deleteMessage(id: string): any;
  /**
   *
   */
  getAllMessages(state: any): any;
  /**
   * Method used to make the api call to get the message detail
   * @param id Message ID
   */
  getMessage(id: string): any;
  /**
   * Optionally used to take the user back
   */
  goBack?(): void;
  /**
   * Method used to make the api call to mark the message as read
   * @param id Message ID
   */
  markAsRead?(id: string): Promise<unknown>;
  /**
   *
   */
  onDelete?(id?: string): void;
  /**
   *
   */
  onUpdate?(): void;
}

export interface IndividualMessageProps extends IndividualMessageBaseProps {
  /**
   * ID of the message to display
   */
  messageId: string;
}

export const IndividualMessage = ({
  getAllMessages,
  getMessage,
  markAsRead,
  deleteMessage,
  baseUrl,
  canReply = false,
  messageId,
  goBack,
  onUpdate,
  onDelete,
}: IndividualMessageProps) => {
  const [confirmMessageOpen, setConfirmMessageOpen] = useState(false);

  const { setOverride } = useMessageContext();

  //
  const { isMobile } = useWidth();

  const router = useRouter();

  const { messages }: GetReceivedMessagesResponse | GetSentMessagesResponse =
    useSelector(getAllMessages);
  const deletedMessages = useSelector(selectDeletedIds);
  const dispatch = useDispatch();
  const { authState } = useSecurity();

  const [message, setMessage] = useState<ReceivedMessage | SentMessage | null>(
    null
  );

  // by default, if there is a message ID supplied, set the state to loading so we don't get a 'no data' flash of content
  const [status, setStatus] = useState<string | null>(
    messageId ? STATUS_LOADING : null
  );

  //
  const showFlag = !!(message && isReceived(message));

  const [flag, setFlag] = React.useState<boolean>(
    !!(message && isReceived(message) && message.actionRequired)
  );

  /**
   * Refresh the view
   */
  const refreshView = async () => {
    try {
      // update the loading status
      setStatus(STATUS_LOADING);

      // load the message
      const data = await getMessage(messageId);

      // loading is complete
      setStatus(STATUS_SUCCESS);

      // assign the message data
      setMessage(data);

      if (isReceived(data)) {
        setFlag(data.actionRequired);
      }

      // if there is a mark as read method, and the message can be marked as read
      if (markAsRead && isReceived(data) && !data.read) {
        // mark call the mark as read method
        markAsRead(messageId).then(() => {
          // set a timeout to update the message read count
          setTimeout(() => {
            dispatch(getUnreadCount());
          }, SERVER_ACTION_DELAY);
        });
      }

      if (onUpdate) {
        onUpdate();
      }
    } catch (e) {
      // there was a big problem...
      setStatus(STATUS_FAILED);

      // display a message to the user
      toast.error('Sorry, There was a problem loading the message');

      if (onUpdate) {
        onUpdate();
      }
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      // if there is a message id (which there should always be)
      if (messageId !== '') {
        delayFire(() => {
          refreshView();
        });
      } else {
        // if the user should be redirected
        if (baseUrl) {
          // failure as there's no message
          setStatus(STATUS_FAILED);

          toast.error('Sorry, no message ID has been supplied');

          // redirect the user back to the messages page/list
          router.history.push(baseUrl);
        }
      }
    }
  }, [authState.isAuthenticated, messageId]);

  const handleFlagToggle = async () => {
    if (!message) return false;

    try {
      const result = await setActionRequired(message.id, {
        actionRequired: !flag,
      });

      if (result) {
        setOverride(message.id, !flag);

        setFlag(!flag);
      }
    } catch (e: unknown) {}
  };

  // loading view
  if (status === STATUS_LOADING) {
    return (
      <Paper>
        <Loading>
          <Typography paragraph>Loading message</Typography>
        </Loading>
      </Paper>
    );
  }

  // Failed view
  if (status === STATUS_FAILED) {
    return (
      <Paper>
        <DataError>
          <Typography paragraph>
            Something went wrong. Please try again.
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              refreshView();
            }}
            endIcon={<RefreshIcon />}
          >
            Refresh
          </Button>
        </DataError>
      </Paper>
    );
  }

  const handleDelete = () => {
    setConfirmMessageOpen(true);
  };

  const onConfirm = async () => {
    dispatch(addDeletedMessageId(messageId));

    if (messages?.length > 1) {
      // @ts-ignore
      const deletedMessageIndex = messages.findIndex(msg => msg.id === messageId);
      const messageIndexToDisplay =
        deletedMessageIndex === messages.length - 1
          ? deletedMessageIndex - 1
          : deletedMessageIndex + 1;
      const messageIdToDisplay = messages[messageIndexToDisplay].id

      if (onDelete) {
        onDelete(messageIdToDisplay);
      }
    } else {
      setMessage(null);
      if (goBack) {
        goBack();
      }
      if (onDelete) {
        onDelete('');
      }
      setConfirmMessageOpen(false);
    }

    await deleteMessage(messageId);
  };
  const onCancel = () => {
    setConfirmMessageOpen(false);
  };

  const isDeleted = deletedMessages.includes(messageId);

  if (message && message.subject && !isDeleted) {
    let body = message.body.replace(/\n/g, '<br><br>');
    body = body.replace(/(<br><br><br><br>)/g, '<br><br>');
    body = body.replace(/(<br><br>Sent)/g, '<br>Sent');

    body = body.replace(/(From:([^>]+)>([\s]+)?Sent:([^>]+)>)/gi, (header) => {
      return `<div class="reply-header">${header}</div>`;
    });

    const parts: Array<string> = body.split(MESSAGE_BREAKER);

    let output: string = String(parts.shift());

    // reply indentation
    for (let i = 0; i < parts.length; i++) {
      let msg = parts[i].trim();
      msg = msg.replace(/^(<br>)(<br>)?/, '');
      msg = msg.replace(/(<br>)(<br>)?$/, '');

      if (i < parts.length / 2) {
        output += `<div class="reply-level">${msg}`;
      } else {
        output += '</div>';
      }
    }

    const ActionRequired = () => {
      if (!showFlag) return null;

      return (
        <Grid item>
          <Tooltip
            title={flag ? 'mark as action complete' : 'mark as action required'}
          >
            <Grid item>
              <Anchor onClick={handleFlagToggle}>
                <PriorityHighIcon fontSize="small" /> {flag ? 'Actioned' : 'Action'}
              </Anchor>
            </Grid>
          </Tooltip>
        </Grid>
      );
    };

    return (
      <Fragment>
        <Helmet>
          <title>{message.subject}</title>
        </Helmet>

        <Confirmation
          isOpen={confirmMessageOpen}
          confirmText="Yes, Delete"
          onConfirm={onConfirm}
          rejectText="No, cancel"
          onReject={onCancel}
        >
          <Typography paragraph>
            Are you sure you want to permanently delete this message
          </Typography>
        </Confirmation>

        <Paper>
          <Box padding={3}>
            {isMobile ? (
              <Fragment>
                <Typography
                  variant="h4"
                  component="h1"
                  style={{ marginBottom: 10 }}
                >
                  {message.subject}
                </Typography>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ marginBottom: 10 }}
                >
                  {message.source.name}
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Typography variant="body2">
                      {isReceived(message)
                        ? getSpecificFormattedDate(message.received)
                        : getSpecificFormattedDate(message.sent)}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Stack
                      spacing={2}
                      alignItems="center"
                      direction="row"
                      justifyContent="flex-end"
                    >
                      {goBack && (
                        <Button
                          color="primary"
                          onClick={goBack}
                          startIcon={<ArrowBackIcon />}
                        >
                          Back
                        </Button>
                      )}

                      <ActionRequired />

                      {onDelete && (
                        <Grid item>
                          <Button
                            color="primary"
                            onClick={handleDelete}
                            startIcon={<DeleteIcon />}
                          >
                            Delete
                          </Button>
                        </Grid>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Fragment>
            ) : (
              <Grid
                container
                spacing={3}
                alignItems="stretch"
                justify="space-between"
              >
                <Grid item xs={12} sm={8}>
                  <Typography variant="h4" component="h1">
                    {message.subject}
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {message.source.name}
                  </Typography>
                  <Typography variant="body2">
                    {isReceived(message)
                      ? getSpecificFormattedDate(message.received)
                      : getSpecificFormattedDate(message.sent)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box textAlign="right">
                    <Stack
                      direction="row"
                      spacing={3}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {goBack && (
                        <Button
                          color="primary"
                          onClick={goBack}
                          startIcon={<ArrowBackIcon />}
                        >
                          Back
                        </Button>
                      )}

                      <ActionRequired />

                      {onDelete && (
                        <Grid item>
                          <Anchor onClick={handleDelete}>
                            <DeleteIcon fontSize="small" /> Delete
                          </Anchor>
                        </Grid>
                      )}
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
          <Divider />
          <Box padding={3}>
            {isReceived(message) && message.actionRequired && (
              <Alert
                severity="warning"
                icon={<PriorityHighIcon style={{ fontSize: 20 }} />}
              >
                <b>Action required:</b> This message requires your attention
              </Alert>
            )}

            <Box marginY={4}>
              <div dangerouslySetInnerHTML={{ __html: output }} />
            </Box>
            {message.documents && (
              <Fragment>
                <Typography paragraph>Attachments</Typography>
                <DocumentList documents={message.documents} />
              </Fragment>
            )}
          </Box>

          {canReply && (
            <Fragment>
              <Divider />
              <Box padding={3}>
                <ReplyForm
                  message={message}
                  messageId={messageId}
                  showForm={
                    isReceived(message) ? message.actionRequired : false
                  }
                />
              </Box>
            </Fragment>
          )}
        </Paper>
      </Fragment>
    );
  }

  // default no data view

  if (messageId === '' || isDeleted) {
    return (
      <Paper>
        <NoData icon={MailIcon}>
          <Typography paragraph>You currently have no messages</Typography>
        </NoData>
      </Paper>
    );
  }

  return (
    <Paper>
      <NoData icon={MailIcon}>
        <Typography paragraph>Unable to load the message</Typography>
      </NoData>
    </Paper>
  );
};
