import { debounce } from '@abrdn-latest/utils';
import { SecureRoute } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import { RegisterForm, useSecurity } from '../authentication';
import { SERVER_ACTION_DELAY } from '../api/constants';
import { getUnreadCount } from '../redux/reducers/messages';
import PageHome from './home/PageHome';
import { AuthLandingPage } from './authentication/AuthLandingPage';
import { LogoutPage } from '../authentication/LogoutPage';
import PageFundCentre from './fund-centre/PageFundCentre';
import PageOurThinking from './our-thinking/PageOurThinking';
import PageOurThinkingArticle from './our-thinking/PageOurThinkingArticle';
import PagePortfolioHoldings from './portfolio-holdings/PagePortfolioHoldings';
import PageFundDetail from './portfolio-holdings/PageFundDetail';
import { RiskWarning } from './portfolio-holdings/components';
import PageNewMessage from './messages/PageNewMessage';
import PageMessages from './messages/PageMessages';
import PageDocuments from './documents/PageDocuments';
import PageNewDocument from './documents/PageNewDocument';
import PageMyDetails from './account/PageAccount';
import PageClientHoldings from './client-holdings/PageAccount';
import { SalesforceRouterPage } from './salesforce-router/SalesforceRouterPage';
import PageTermsAndConditions from './terms-and-conditions/PageTermsAndConditions';
import PageError from './error/PageError';
import Page404 from './not-found/PageNotFound';

export function Routes() {
  const dispatch = useDispatch();
  const { authState, settings } = useSecurity();
  const { pathname } = useLocation();

  const debouncedDispatch = debounce(dispatch, SERVER_ACTION_DELAY * 3);

  // temporary hot fix until proper redux message handling is implemented
  useEffect(() => {
    if (authState.isAuthenticated) {
      debouncedDispatch(getUnreadCount());
    }
  }, [pathname, authState.isAuthenticated]);

  return (
    <Switch>
      <SecureRoute exact path="/home" component={PageHome} />

      <Route exact path="/auth/landing" component={AuthLandingPage} />
      <Route exact path="/auth/register" component={RegisterForm} />
      <SecureRoute path="/logout" component={LogoutPage} />

      <SecureRoute exact path="/fund-centre" component={PageFundCentre} />

      <SecureRoute exact path="/our-thinking" component={PageOurThinking} />
      {process.env.REACT_APP_FEATURE_OUR_THINKING && (
        <SecureRoute
          path="/our-thinking/:article"
          component={PageOurThinkingArticle}
        />
      )}

      <SecureRoute
        exact
        path="/portfolio-holdings"
        component={PagePortfolioHoldings}
      />
      <SecureRoute
        path="/portfolio-holdings/:fundCode"
        component={PageFundDetail}
      />

      <SecureRoute
        path="/portfolio-holdings/"
        component={RiskWarning}
      />

      <SecureRoute exact path="/message/new" component={PageNewMessage} />
      <SecureRoute exact path="/messages" component={PageMessages} />
      <SecureRoute exact path="/messages/:mailbox/" component={PageMessages} />
      <SecureRoute
        exact
        path="/messages/:mailbox/:messageId"
        component={PageMessages}
      />

      <SecureRoute
        exact
        path={['/documents', '/documents/:category']}
        component={PageDocuments}
      />
      <SecureRoute exact path="/document/new" component={PageNewDocument} />

      {settings['ClientHoldings.Enabled'] && (
        <SecureRoute
          path="/accounts/:clientId/account/:accountId/holdings"
          component={PageFundDetail}
          exact
        />
      )}
      {settings['ClientHoldings.Enabled'] && (
        <SecureRoute
          path="/accounts/:client/:section"
          component={PageClientHoldings}
          exact
        />
      )}

      <SecureRoute exact path="/account" component={PageMyDetails} />

      <SecureRoute path="/sfredirect/:page" component={SalesforceRouterPage} />
      <Route path="/terms-and-conditions" component={PageTermsAndConditions} />
      <Route exact path="/error/:code" component={PageError} />
      <Route component={Page404} />
    </Switch>
  );
}
