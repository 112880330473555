import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { KeyboardArrowLeftIcon } from '../icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline-flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

interface Props {
  onBack(): void;
  type: 'registration' | 'login';
}

export function ErrorDialog(props: Props) {
  const styles = useStyles();
  return (
    <>
      <Box mb={10}>
        <Typography variant="h4" component="h3">
          Oops!
        </Typography>

        <Typography paragraph variant="body2">
          It looks like we are currently experiencing some technical
          difficulties and have been unable to complete your {props.type}.
        </Typography>

        <Typography paragraph variant="body2">
          Please try again later or feel free to contact us
          at <b>ConnectHelp@abrdn.com</b> for assistance.
        </Typography>
      </Box>
      <Box onClick={props.onBack} className={styles.link}>
        <KeyboardArrowLeftIcon style={{ marginRight: 4, width: 24, height: 24 }} />
        <b style={{ fontSize: 14 }}>Back to login</b>
      </Box>
    </>
  );
}
