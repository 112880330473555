import { sendApiRequest } from '../utils';

type Response = Array<{
  detail: string;
  type: string;
}>;

interface OktaCredentials {
  clientId: string;
  issuer: string;
  salesforceUrl: string;
}

export async function getOktaCredentials(): Promise<OktaCredentials> {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/account/registration/oktacredentials`;

  const response = await sendApiRequest<Response>(endpoint);

  return {
    issuer: response.find(item => item.type === 'Okta_issuer')?.detail ?? '',
    clientId: response.find(item => item.type === 'Okta_clientId')?.detail ?? '',
    salesforceUrl: response.find(item => item.type === 'Sales_Url')?.detail ?? '',
  }
}
