// TODO: comment
import { Banner } from '@abrdn/banner';
import { Box, Container, Typography } from '@material-ui/core';
import { Breadcrumbs } from 'components/navigation';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export const TermsAndConditions = () => {
  return (
    <Fragment>
      <Typography variant="h5" component="h2">
        1. ABOUT US AND THESE TERMS
      </Typography>
      <Typography paragraph>
        ABRDN CONNECT is operated by abrdn plc. WE are registered in Scotland
        under company number SC286832 and have our registered office at 1 George
        Street, Edinburgh EH2 2LL. Our main trading address is 1 George Street,
        Edinburgh EH2 2LL. Our VAT number is GB270347469.
      </Typography>
      <Typography paragraph>
        By clicking YOUR acceptance the first time YOU log in and by continuing
        to use ABRDN CONNECT thereafter, YOU are confirming that YOU are
        accepting these TERMS.
      </Typography>
      <Typography paragraph>
        These TERMS and the legal information notice (available{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={'https://www.abrdn.com/en/uk/institutional/legal-notice'}
          style={{ color: 'inherit' }}
        >
          here
        </a>
        ) apply between you and US in connection with use by YOU of ABRDN
        CONNECT. How we process personal data is set out at paragraph 16.
      </Typography>
      <Typography paragraph>
        These TERMS are in addition to, and do not replace or supersede, any
        other agreements YOU have entered to with US or a member of the ABRDN
        GROUP in relation to your INVESTMENTS or otherwise (including any
        investment management agreements).
      </Typography>
      <Typography paragraph>
        YOU can view these TERMS (and any subsequent updates made to them) in
        ABRDN CONNECT at any time.
      </Typography>
      <Typography paragraph>
        Words which are in capital letters in these TERMS have a specific
        meaning, as set out in the glossary at the end of these TERMS:
      </Typography>
      <Typography paragraph>
        Please note paragraphs 2 and 9 below, which include important
        information on ABRDN CONNECT and OUR liability to YOU.
      </Typography>
      <Typography variant="h5" component="h2">
        2. ABRDN CONNECT
      </Typography>
      <Typography paragraph>
        2.1 ABRDN CONNECT allows YOU to interact and engage with US online by
        providing a digital channel to view information in relation to
        INVESTMENTS, communicate with us, and access other content relevant to
        YOU.
      </Typography>
      <Typography paragraph>
        2.2 ABRDN CONNECT allows YOU and individual users to access information
        on INVESTMENTS internationally.
      </Typography>
      <Typography paragraph>
        2.3 Not all of the INVESTMENTS displayed on ABRDN CONNECT may be
        available for YOU to invest in. This may be due to the location of the
        investment or the prevailing terms of the provider of the investment.
      </Typography>
      <Typography paragraph>
        2.4 Availability of the content (including information on INVESTMENTS)
        in ABRDN CONNECT is not to be deemed as marketing of the investments.
        Neither the SERVICES nor the INFORMATION will be deemed to constitute an
        offer or solicitation to sell investments in any jurisdiction.
      </Typography>
      <Typography paragraph>
        2.5 WE are authorised and regulated by the FCA. WE do not provide
        investment advice or tax advice to you or your clients through the
        SERVICES.
      </Typography>
      <Typography variant="h5" component="h2">
        3. Intended audience and use
      </Typography>
      <Typography paragraph>
        3.1 ABRDN CONNECT is intended for use by clients of US who have
        INVESTMENTS with US or a member of the ABRDN GROUP, or by members of the
        general public who are interested in OUR fund information, and the
        INFORMATION provided on ABRDN CONNECT reflects this.
      </Typography>
      <Typography paragraph>
        3.2 As ABRDN CONNECT, the SERVICES and the INFORMATION are issued in the
        UK, these may not be fully compliant with any local laws or regulations
        outside the UK, the Channel Islands and the Isle of Man
      </Typography>
      <Typography variant="h5" component="h2">
        4. YOUR Registration
      </Typography>
      <Typography paragraph>
        4.1 YOU must ensure that the information and details YOU submit to US on
        the ONLINE REGISTRATION and when using ABRDN CONNECT and the SERVICES is
        current, relevant and accurate. YOU must notify US as soon as possible
        if YOU become aware of any changes to the information or details.
      </Typography>
      <Typography paragraph>
        4.2 WE may have to pass information relating to YOU and YOUR INVESTMENTS
        to other members of ABRDN GROUP. If YOU do not wish to agree to the
        sharing of YOUR information with ABRDN GROUP, do not proceed with YOUR
        registration (or if YOU have already registered, please let US know as
        soon as possible that YOU would like to cancel YOUR registration). Data
        protection is explained further in paragraph 16.
      </Typography>
      <Typography paragraph>
        4.3 Subject to any laws, rules and regulations applicable to US, WE have
        full discretion to accept, or not to accept, an application to register
        and WE reserve the right to reject YOUR application without giving a
        reason for doing so.
      </Typography>
      <Typography variant="h5" component="h2">
        5. YOUR USE OF ABRDN CONNECT
      </Typography>
      <Typography paragraph>
        5.1 YOU will act lawfully, honestly and in a professional and diligent
        manner in YOUR use of ABRDN CONNECT and SERVICES. YOU will ensure that
        YOU do not act in a manner which could adversely affect our reputation.
      </Typography>
      <Typography paragraph>
        5.2 Any documentation YOU upload to ABRDN CONNECT must:
      </Typography>
      <Typography paragraph>· be accurate (where it states facts).</Typography>
      <Typography paragraph>
        · comply with the law applicable in England and Wales and in any country
        from which it is posted,
      </Typography>
      <Typography paragraph>and must not:</Typography>
      <Typography paragraph>
        · infringe any intellectual property rights (for example copyright,
        database rights or trademarks) of any other person.
      </Typography>
      <Typography paragraph>
        · breach any legal duty owed to a third party, such as a contractual
        duty or a duty of confidence.
      </Typography>
      <Typography paragraph>
        YOU agree that no other types of documentation or data may be uploaded
        to ABRDN CONNECT.
      </Typography>
      <Typography paragraph>
        If YOU upload any data or documents which do not comply with this
        paragraph, YOU agree to contact US immediately at the details set out in
        paragraph 18.
      </Typography>
      <Typography paragraph>
        5.3 YOU must use ABRDN CONNECT, the SERVICES and the INFORMATION solely
        for YOUR own purposes and must not make these available to third parties
        without the prior written consent of US.
      </Typography>
      <Typography paragraph>
        5.4 YOU must not access or attempt to access any INFORMATION other than
        INFORMATION relating to YOU as a user of ABRDN CONNECT and the SERVICES.
      </Typography>
      <Typography paragraph>
        5.5 YOU must log out of ABRDN CONNECT that YOU have accessed and close
        YOUR web browser when YOU have finished using ABRDN CONNECT and the
        SERVICES. If YOU do not do this, INFORMATION about YOU may appear on the
        screen of the computer when it is next used and WE cannot be held
        responsible for third parties having access to this INFORMATION.
      </Typography>
      <Typography variant="h5" component="h2">
        6. Security
      </Typography>
      <Typography paragraph>
        6.1 YOU must ensure that the USERNAME, PASSWORD and answers to YOUR
        security questions are memorised and that all reasonable precautions are
        taken to prevent these from being obtained by anyone else. For instance,
        these details must not:
      </Typography>
      <Typography paragraph>
        · be written down and/or disclosed to anyone else;
      </Typography>
      <Typography paragraph>
        · be recorded in such a way that these could be understood by anyone
        else; or
      </Typography>
      <Typography paragraph>
        · be stored on a computer so that the computer remembers them
        automatically.
      </Typography>
      <Typography paragraph>
        6.2 The PASSWORD and the answers to YOUR security questions must not be
        disclosed to any staff of ABRDN GROUP. OUR staff should never ask for
        the PASSWORD or the answers to YOUR security questions to be disclosed
        to them.
      </Typography>
      <Typography paragraph>
        6.3 If YOU believe that someone else knows the PASSWORD or is using the
        PASSWORD to access the SERVICES without permission, YOU must ensure
        that:
      </Typography>
      <Typography paragraph>
        · the PASSWORD is changed using our online facility. YOU must ensure
        that this is done immediately or, where this occurs during ABRDN CONNECT
        down time, immediately upon ABRDN CONNECT becoming available again; and
      </Typography>
      <Typography paragraph>
        · WE are informed by phone as soon as possible (please see paragraph 18
        for how to contact US).
      </Typography>
      <Typography paragraph>
        6.4 Some organisations may offer AGGREGATION SERVICES and may ask YOU to
        disclose to them the USERNAME and PASSWORD and/or appoint them as YOUR
        agent. YOU must not:
      </Typography>
      <Typography paragraph>
        · disclose the USERNAME or PASSWORD to a provider of AGGREGATION
        SERVICES; and/or
      </Typography>
      <Typography paragraph>
        · appoint a third party as YOUR agent to access the SERVICES using the
        USERNAME and PASSWORD.
      </Typography>
      <Typography paragraph>
        6.5 WE have no control over AGGREGATION SERVICES and cannot ensure the
        accuracy of data displayed or instructions transferred to US by an
        AGGREGATION SERVICE. WE cannot ensure the security of any data, USERNAME
        or PASSWORD held by an AGGREGATION SERVICE.
      </Typography>
      <Typography paragraph>
        6.6 If YOU do provide the USERNAME and/or PASSWORD to an AGGREGATION
        SERVICE, WE will not be responsible for any loss or expense suffered by
        YOU as a result of this disclosure.
      </Typography>
      <Typography variant="h5" component="h2">
        7. Availability of the client portal and the services
      </Typography>
      <Typography paragraph>
        7.1 ABRDN CONNECT and the SERVICES are provided during the hours stated
        on ABRDN CONNECT or as otherwise indicated by US, however, WE cannot
        guarantee that ABRDN CONNECT or the SERVICES will always be available
        during the times stated. ABRDN CONNECT and/or the SERVICES may be
        temporarily unavailable or restricted for administrative or other
        reasons. If this happens WE will endeavour to restore their availability
        as quickly as possible.
      </Typography>
      <Typography paragraph>
        7.2 WE may for security reasons restrict or remove YOUR access to ABRDN
        CONNECT and the SERVICES at our reasonable discretion if:
      </Typography>
      <Typography paragraph>
        · the incorrect USERNAME or PASSWORD is keyed in on successive logon
        attempts;
      </Typography>
      <Typography paragraph>
        · YOU or WE suspect that an unauthorised person is attempting to access
        ABRDN CONNECT and/or the SERVICES using the USERNAME; or
      </Typography>
      <Typography paragraph>
        · for valid operational or security reasons, YOU or WE believe it is
        necessary and appropriate to do so.
      </Typography>
      <Typography variant="h5" component="h2">
        8. Accuracy of information
      </Typography>
      <Typography paragraph>
        8.1 WE will take all reasonable care to ensure that all the INFORMATION
        provided by US is accurate, current and complies with relevant UK laws
        and regulations (as applicable) as at the date of issue.
      </Typography>
      <Typography paragraph>
        8.2 Although carefully verified, WE do not accept liability or
        responsibility for the completeness or accuracy of the INFORMATION and
        WE simply make this available to YOU for YOUR convenience.
      </Typography>
      <Typography variant="h5" component="h2">
        9. General liability
      </Typography>
      <Typography paragraph>
        9.1 Irrespective of the other provisions in these TERMS, WE are
        responsible to YOU for:
      </Typography>
      <Typography paragraph>
        · any claim YOU may have against US for death or personal injury that
        has been caused as a direct result of our negligence;
      </Typography>
      <Typography paragraph>
        · losses YOU suffer as a result of any fraud committed by US;
      </Typography>
      <Typography paragraph>
        · losses YOU suffer as a result of any intentional failure by US to
        fulfil our obligations under these TERMS.
      </Typography>
      <Typography paragraph>
        9.2 WE and YOU will comply at all times with all relevant laws and
        applicable regulations relating to ABRDN CONNECT and/or the services.
      </Typography>
      <Typography paragraph>
        9.3 WE and YOU agree to co-operate with each other in connection with
        the application of any relevant laws and regulations.
      </Typography>
      <Typography paragraph>
        9.4 Nothing in these TERMS will exclude or limit any duty or liability
        WE may have under the regulatory system as defined by the FCA RULES.
      </Typography>
      <Typography paragraph>
        9.5 Nothing in these TERMS will exclude or limit any obligation WE may
        have in common law, and in particular for fraud or for misrepresentation
        as to a fundamental matter.
      </Typography>
      <Typography variant="h5" component="h2">
        10. Intellectual property
      </Typography>
      <Typography paragraph>
        10.1 Intellectual property (including copyright) in:
      </Typography>
      <Typography paragraph>· the pages of ABRDN CONNECT;</Typography>
      <Typography paragraph>
        · the screens displaying the pages of ABRDN CONNECT; and
      </Typography>
      <Typography paragraph>· the INFORMATION and its arrangement</Typography>
      <Typography paragraph>
        is owned by or licensed to members of ABRDN GROUP, unless otherwise
        indicated.
      </Typography>
      <Typography paragraph>
        10.2 &apos;abrdn&apos; and the related logos are registered trademarks
        of members of ABRDN GROUP. Members of ABRDN GROUP may also claim trade
        mark rights in other marks contained in the INFORMATION or on ABRDN
        CONNECT from time to time.
      </Typography>
      <Typography paragraph>
        10.3 Reproduction of any part of our copyright or trade mark materials,
        or the look and feel of ABRDN CONNECT, without the prior written consent
        of a member of ABRDN GROUP, is strictly prohibited unless this is for
        YOUR proper use of ABRDN CONNECT or the SERVICES or for private,
        non-commercial viewing or downloading purposes.
      </Typography>
      <Typography variant="h5" component="h2">
        11. Downloads
      </Typography>
      <Typography paragraph>
        11.1 Any software, multimedia files, photographs, reports and other
        documents made available through ABRDN CONNECT are downloaded at YOUR
        own risk. WE do not warrant the suitability of any such downloads and
        accept no liability for any problems with YOUR computer or mobile
        equipment, computer or mobile programs, data, systems or other
        proprietary material that may arise as a result. If YOU are in any doubt
        as to the suitability of any such downloads for YOUR computer, it is
        recommended that YOU obtain specialist advice before downloading.
      </Typography>
      <Typography variant="h5" component="h2">
        12. Third party websites
      </Typography>
      <Typography paragraph>
        12.1 WE may provide YOU with links to websites operated or owned by
        third parties, which allow YOU to access and use third party material
        and information. WE do not have any control over these third party
        websites or the information contained on them and do not accept any
        responsibility or liability in connection with access or use of them. WE
        do not endorse, authorise or sponsor, nor are WE affiliated to, such
        websites or their content, owners or providers.
      </Typography>
      <Typography variant="h5" component="h2">
        13. Computer misuse
      </Typography>
      <Typography paragraph>
        13.1 YOU must not perform any DENIAL-OF-SERVICE ATTACK on ABRDN CONNECT.
        YOU must not:
      </Typography>
      <Typography paragraph>
        · misuse ABRDN CONNECT by knowingly introducing computer viruses or any
        other material which is malicious or technologically harmful; or
      </Typography>
      <Typography paragraph>
        · attempt to gain unauthorised access to ABRDN CONNECT, the server on
        which ABRDN CONNECT is stored or any server, computer or database
        connected to ABRDN CONNECT.
      </Typography>
      <Typography paragraph>
        13.2 By breaching these provisions YOU may commit a criminal offence
        under the Computer Misuse Act 1990 or similar law or regulation in
        another jurisdiction (as applicable). WE will report any such breach to
        the relevant law enforcement authorities and will co-operate with those
        authorities and may disclose YOUR identity to them. In the event of such
        a breach, YOUR access to ABRDN CONNECT will be revoked immediately.
      </Typography>
      <Typography paragraph>
        13.3 WE recommend that YOU employ reasonable virus detection and
        protection measures when accessing ABRDN CONNECT. WE will not be
        responsible for any loss or damage resulting from:
      </Typography>
      <Typography paragraph>
        · any attack by a third party on our systems; or
      </Typography>
      <Typography paragraph>
        · any computer virus or other malicious or technologically harmful
        material that may infect YOUR computer or mobile equipment, computer or
        mobile programs, data, systems or other proprietary material due to use
        of ABRDN CONNECT, or due to downloading of any material posted on ABRDN
        CONNECT or any website linked to them.
      </Typography>
      <Typography variant="h5" component="h2">
        14. Electronic communications
      </Typography>
      <Typography paragraph>
        14.1 WE consider electronic communications (including emails and
        messages sent through ABRDN CONNECT) to have the same legal status as
        documents sent to US by post. YOU agree not to contest the validity or
        enforceability of an electronic communication (including an email or
        other form of secure message) which relates to an instruction. YOU also
        expressly agree not to use the absence of a printed or hand written
        document as an excuse not to comply with YOUR obligations under these
        TERMS.
      </Typography>
      <Typography paragraph>
        14.2 If YOU choose to send US an electronic communication (including an
        email or message), YOU do so at YOUR own risk as there can be no
        guarantee that WE will receive it, or that its content will remain
        private or unaltered during its transmission to US. WE will accept no
        liability for any loss or damage YOU may suffer as a result of this. If
        this causes concern, YOU may prefer to contact US by phone or post.
      </Typography>
      <Typography paragraph>
        14.3 WE reserve the right to monitor the use and content of electronic
        communications (including emails and other forms of secure message)
        which are sent from and received by US for the purposes of ensuring
        compliance with OUR own electronic communications policy, and
        identifying and taking action against unlawful or improper use of, or
        attacks on, OUR systems.
      </Typography>
      <Typography paragraph>
        14.4 Where possible, WE virus scan and retain all electronic
        communications (including emails), but WE will not be responsible for
        any damage caused by a virus or alteration by a third party, after an
        email or electronic message has been sent by US. WE recommend that YOU
        employ reasonable virus detection and protection measures when accessing
        any emails or electronic messages from US.
      </Typography>
      <Typography variant="h5" component="h2">
        15. Changes to these terms
      </Typography>
      <Typography paragraph>
        15.1 WE can make reasonable and appropriate changes to these TERMS (or
        issue a replacement set of terms and conditions in their place) by
        notifying you through ABRDN CONNECT at any time:
      </Typography>
      <Typography paragraph>
        · if changes to the legal or regulatory requirements applying to ABRDN
        CONNECT, the SERVICES or the INFORMATION need to be reflected in these
        TERMS;
      </Typography>
      <Typography paragraph>
        · if new industry guidance and codes of practice which are there to
        raise standards of customer protection need to be reflected in these
        TERMS;
      </Typography>
      <Typography paragraph>
        · if it becomes impossible or impractical, in OUR reasonable opinion, to
        carry out any of these TERMS as a result of a change in the law or
        regulation or other circumstances beyond OUR control;
      </Typography>
      <Typography paragraph>
        · to reflect improvements to ABRDN CONNECT, the SERVICES and/or the
        INFORMATION that technological, service or propositional enhancements
        have allowed US to make;
      </Typography>
      <Typography paragraph>
        · if WE reasonably believe that the changes are necessary in the
        interests of OUR business; or
      </Typography>
      <Typography paragraph>
        · to improve the clarity of any of these TERMS.
      </Typography>
      <Typography paragraph>
        15.2 WE will let YOU know of any change made to these TERMS that may be
        to YOUR disadvantage. If YOU object to a change made by US please
        contact US (see paragraph 18 for how to contact US), however, please
        note YOUR only recourse may be to terminate these TERMS (as explained in
        paragraph 17).
      </Typography>
      <Typography variant="h5" component="h2">
        16. Use of personal data
      </Typography>
      <Typography paragraph>
        16.1 WE will collect and use limited personal data about YOU and if
        applicable YOUR employees and representatives ( i.e. YOUR name, contact
        email) in order to verify YOUR identity, to provide access to the
        SERVICES and to comply with relevant laws and regulations. Personal data
        has the meaning given under applicable DATA PROTECTION LAWS.
      </Typography>
      <Typography paragraph>
        16.2 WE will share your data with other members of ABRDN GROUP and other
        companies WE work with to support us in the provision of services to
        YOU.
      </Typography>
      <Typography paragraph>
        16.3 WE will only share personal data: (i) where it’s necessary and
        lawful to do so; and (ii) in line with our obligations under applicable
        DATA PROTECTION LAWS in order to keep this information safe and secure.
      </Typography>
      <Typography variant="h5" component="h2">
        17. Termination
      </Typography>
      <Typography paragraph>
        17.1 YOU can terminate these TERMS by giving US notice of this, in the
        manner outlined in paragraph 17.3.
      </Typography>
      <Typography paragraph>
        17.2 WE may terminate these TERMS and YOUR right to use ABRDN CONNECT
        and the SERVICES:
      </Typography>
      <Typography paragraph>
        · by giving YOU 30 days&apos; notice, as outlined in paragraph 17.3. WE
        will notify YOU of the options available to YOU at the time;
      </Typography>
      <Typography paragraph>
        · by notifying YOU, as outlined in paragraph 17.3, if YOU commit a
        material breach of these TERMS and (if it is remediable) YOU fail to
        remedy the breach within 10 working days of being asked by US to do so;
        or
      </Typography>
      <Typography paragraph>
        · by giving YOU as much advance notice as possible, as outlined in
        paragraph 17.3, in the event that WE have to withdraw ABRDN CONNECT
        and/or the SERVICES for ABRDN CONNECT security, legal, commercial or
        regulatory reasons.
      </Typography>
      <Typography paragraph>
        17.3 The notices that either WE or YOU are required to serve on the
        other under this paragraph 17 must be in writing and can be served
        either by email or by first class post to the last notified address of
        the party (see paragraph 18 for details of our contact address). If a
        notice is served by email, it will be deemed to be delivered on the day
        it was sent provided no non-delivery message is received by the sender.
        If a notice is served by first class post, it will be deemed delivered
        two working days after being posted and in proving such service it shall
        be sufficient to prove that the envelope was properly addressed, stamped
        and posted.
      </Typography>
      <Typography paragraph>
        17.4 YOUR access to ABRDN CONNECT will be revoked upon termination of
        these TERMS, howsoever occurring.
      </Typography>
      <Typography variant="h5" component="h2">
        18. How to contact us
      </Typography>
      <Typography paragraph>
        18.1 If YOU have any questions about ABRDN CONNECT or the SERVICES, YOU
        can contact YOUR Relationship Manager.
      </Typography>
      <Typography paragraph>
        18.2 Calls may be monitored and/or recorded to protect both YOU and US
        and to help with OUR training. Call charges will vary.
      </Typography>
      <Typography paragraph>
        18.3 Our main contact address is abrdn plc, 1 George Street, Edinburgh,
        EH2 2LL.
      </Typography>
      <Typography variant="h5" component="h2">
        19. Complaints
      </Typography>
      <Typography paragraph>
        19.1 If YOU have a concern or complaint about ABRDN CONNECT and/or the
        SERVICES, please contact US (see paragraph 18 for how to contact US). We
        will acknowledge your complaint with 5 business days and liaise with YOU
        regarding your issue and attempt to fully resolve it during this time.
        In some cases we may require additional information and continue with
        the investigation into your complaint, however will try to send a final
        response within 4 weeks of receipt of your complaint. If we are unable
        to provide you with a final response within this time we will send you
        an update. We will endeavour to send a final response to you within 8
        weeks of receipt of your complaint. If we are unable to provide you with
        a final response within this time frame, we will write to you explaining
        why and advise you when you can expect a final response.
      </Typography>
      <Typography paragraph>
        19.2 Complaints received from professional clients and eligible
        counterparties will be handled in the same way as complaints received
        from retail clients. However, professional clients and eligible
        counterparties may not meet the definition of “eligible complainant” and
        therefore may not have access the applicable Alternative Dispute
        Resolution service within their jurisdiction.
      </Typography>
      <Typography variant="h5" component="h2">
        20. Cookies
      </Typography>
      <Typography paragraph>
        20.1 ABRDN CONNECT uses cookies. For more information, please read OUR{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={'https://www.abrdn.com/en/uk/institutional/cookie-policy'}
          style={{ color: 'inherit' }}
        >
          cookie policy
        </a>{' '}
        at the LEGAL INFORMATION NOTICE.
      </Typography>
      <Typography variant="h5" component="h2">
        21. ABRDN GROUP
      </Typography>
      <Typography paragraph>
        21.1 Each member of ABRDN GROUP shall be entitled to recover any loss
        suffered by it and generally to enforce these TERMS in its own right in
        accordance with the provisions of the Contract (Rights of Third Parties
        Act) 1999.
      </Typography>
      <Typography variant="h5" component="h2">
        22. Governing law
      </Typography>
      <Typography paragraph>
        22.1 If TERMS shall be governed by the laws of England and Wales, and
        YOU and WE shall be deemed to submit to the exclusive jurisdiction of
        the courts of England and Wales.
      </Typography>

      {/* Glossary */}
      <Typography variant="h5" component="h2">
        23. GLOSSARY
      </Typography>
      <Typography paragraph>
        23.1 In these terms, these capitalised words have the following
        meanings:
      </Typography>

      <Typography paragraph>AGGREGATION SERVICES</Typography>
      <Typography paragraph>
        are services offered by third parties where the third party will:
      </Typography>
      <Typography paragraph>
        collect and manage YOUR financial account information for YOU and
        display this information for YOU in one place such as a single web page;
        and/or
      </Typography>
      <Typography paragraph>
        carry out transactions for YOU using the USERNAME and PASSWORD.
      </Typography>
      <Typography paragraph>CORPORATE ENTITY</Typography>
      <Typography paragraph>
        includes a limited company, a sole trader, a partnership or limited
        liability partnership, a club, a society, a recognised or registered
        charity, and any other entity which has its own legal identity that is
        distinct from the individuals that work for it.
      </Typography>
      <Typography paragraph>DATA PROTECTION LAWS</Typography>
      <Typography paragraph>
        means any laws or regulations that apply from time to time to the
        PROCESSING of PERSONAL DATA by either party under these TERMS and to
        include without limitation Regulation (EU) 2016/679 of 27 April 2016
        (GDPR), all national implementing legislation (including the Data
        Protection Act 2018) and subordinate legislation in the United Kingdom
        and any applicable decisions and guidance made under them.
      </Typography>
      <Typography paragraph>DENIAL-OF-SERVICE ATTACK</Typography>
      <Typography paragraph>
        means an attempt to make a computer resource unavailable to its intended
        users.
      </Typography>
      <Typography paragraph>FCA</Typography>
      <Typography paragraph>
        means the Financial Conduct Authority or any successor regulator which
        regulates our investment business. The FCA can be contacted at 25 The
        North Colonnade, Canary Wharf, London E14 5HS. The FCA can be contacted
        at 12 Endeavour Square, London, E20 1JN.
      </Typography>
      <Typography paragraph>FCA RULES</Typography>
      <Typography paragraph>
        means the Handbook of Rules and Guidance of the FCA, as amended from
        time to time.
      </Typography>
      <Typography paragraph>INFORMATION</Typography>
      <Typography paragraph>
        means any and all information contained on ABRDN CONNECT being
        registered for or provided as part of the SERVICES.
      </Typography>
      <Typography paragraph>INVESTMENTS</Typography>
      <Typography paragraph>
        means funds or other investment products offered by US or other members
        of the ABRDN GROUP
      </Typography>
      <Typography paragraph>ONLINE REGISTRATION</Typography>
      <Typography paragraph>
        means the online registration completed by YOU as part of the
        registration process to enable YOU to use ABRDN CONNECT and the
        SERVICES.
      </Typography>
      <Typography paragraph>PASSWORD</Typography>
      <Typography paragraph>
        means the password chosen by YOU during registration (or any subsequent
        reset) that, together with the USERNAME, enables individual access to
        ABRDN CONNECT and the SERVICES.
      </Typography>
      <Typography paragraph>SERVICES</Typography>
      <Typography paragraph>
        means the provision of online services through ABRDN CONNECT.
      </Typography>
      <Typography paragraph>ABRDN GROUP</Typography>
      <Typography paragraph>
        means abrdn plc which is registered in Scotland (company number
        SC286832), together with its subsidiaries, subsidiary undertakings and
        associated companies (whether direct or indirect) from time to time and
        a member of ABRDN GROUP shall mean abrdn plc or any of its subsidiaries,
        subsidiary undertakings or associated companies (whether direct or
        indirect) from time to time.
      </Typography>
      <Typography paragraph>TERMS</Typography>
      <Typography paragraph>
        means the terms and conditions contained in this online document.
      </Typography>
      <Typography paragraph>USERNAME</Typography>
      <Typography paragraph>
        means the username chosen by YOU during registration (or any subsequent
        resets) that, together with the PASSWORD, enables individual access to
        ABRDN CONNECT and the SERVICES.
      </Typography>
      <Typography paragraph>UK</Typography>
      <Typography paragraph>
        means the United Kingdom of Great Britain and Northern Ireland,
        excluding the Isle of Man and the Channel Islands.
      </Typography>
      <Typography paragraph>YOU</Typography>
      <Typography paragraph>
        means the person (including a private individual or CORPORATE ENTITY)
        who may have INVESTMENTS, and who is specified as the user of ABRDN
        CONNECT and SERVICES on the ONLINE REGISTRATION (and YOUR shall be
        construed accordingly).
      </Typography>
    </Fragment>
  );
};

const PageTermsAndConditions = () => {
  const { t } = useTranslation(['common']);

  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.terms')}</title>
      </Helmet>

      <Banner>
        <Breadcrumbs
          inBanner
          trail={[
            { label: 'Home', href: '/home' },
            { label: 'Terms & Conditions' },
          ]}
        />
        <Typography variant="h3" component="h1">
          {t('common:titles.terms')}
        </Typography>
      </Banner>

      <Box paddingY={6}>
        <Container>
          <Box maxWidth={860}>
            <TermsAndConditions />
          </Box>
        </Container>
      </Box>
    </Fragment>
  );
};

export default PageTermsAndConditions;
