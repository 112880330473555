import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSecurity } from '../../authentication';
import { useRouter } from '../../@abrdn-latest/use';

const Page404 = () => {
  const { authState } = useSecurity();
  const router = useRouter();

  useEffect(() => {
    if (authState.isAuthenticated) {
      router.replace('/home');
    } else {
      router.replace('/auth/landing');
    }
  }, [authState, router]);

  return null;
};

export default Page404;
