import { ClientAccounts } from 'api/types';

/**
 * Get a list of unique currencies
 * @param accounts client accounts
 * @returns a unique set of currencies
 */
export const getUniqueCurrencies = (accounts: ClientAccounts): string[] => {
  const currencies: string[] = accounts.map((acc) => {
    return acc.valuation.currencyCode;
  });

  const uniq = [...new Set(currencies)];

  return uniq;
};
