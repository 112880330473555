import { PropsWithChildren, useEffect, useState } from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { userStatus } from 'api';
import { getOktaCredentials } from 'api/auth/oktaCredentials';
import { Splash } from '../components/splash';

let oktaAuth: OktaAuth;

export function OktaSecurity({ children }: PropsWithChildren<{}>) {
  const [oktaLoaded, setOktaLoaded] = useState(false);

  useEffect(() => {
    async function setOktaAuth() {
      if (!oktaAuth) {
        await userStatus();
        const { issuer, clientId } = await getOktaCredentials();
        oktaAuth = new OktaAuth({
          issuer,
          clientId,
          redirectUri: `${window.location.origin}${window.location.pathname}`,
          postLogoutRedirectUri: `${window.location.origin}${window.location.pathname}`,
          scopes: ['openid', 'profile', 'email', 'offline_access'],
          pkce: true,
          storageManager: {
            token: {
              storageType: 'sessionStorage',
            }
          },
        });
        setOktaLoaded(true);
      }
    }
    setOktaAuth();
  }, []);

  if (!oktaLoaded || !oktaAuth) {
    return <Splash />
  }

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={() => window.location.replace('#/home')}
    >
      {children}
    </Security>
  );
}
