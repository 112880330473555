import { useRouter, useSearchParams } from '@abrdn-latest/use';
import { Redirect } from 'react-router-dom';
import { useClientHoldings } from 'pages/client-holdings/data';

const pathMap: Record<number, string> = {
  0: '/logout',
  1: '/home',
  2: '/our-thinking',
  3: '/fund-centre',
  4: '/portfolio-holdings',
  5: '/documents',
  // 6: '/accounts/{id}',
  7: '/account',
  8: '/terms-and-conditions',
};

export function SalesforceRouterPage() {
  const { clients } = useClientHoldings();
  const router = useRouter();
  // @ts-ignore
  const page = router.query.page ?? router.query.Page;
  const pageIdx = Number(page);

  // Accounts
  if (pageIdx === 6 && clients.length > 0) {
    const accountId = clients[0].id;
    return <Redirect to={`/accounts/${accountId}/accounts`} />;
  }

  return <Redirect to={pathMap[pageIdx] ?? '/home'} />;
}
